import { IngredientProps } from 'src/types/ingredient';

import { STATUS } from '../global';

export const INGREDIENT_PAGE_TABS = [
  { value: 'ingredient', label: 'Ingredient' },
  // { value: 'activity', label: 'Activity' }, // TODO: uncomment when the feature is implemented
];

export const INGREDIENT_FORM_INITIAL_VALUES: IngredientProps = {
  typename: 'Ingredient',
  createdAt: null,
  createdBy: null,
  category: null,
  ingredientId: null,
  name: null,
  namePlural: null,
  parentId: null,
  photo: null,
  prep: null,
  status: null,
  type: null,
  unitId: null,
  updatedAt: null,
  updatedBy: null,
  video: null,
  weight: null,
  subIngredients: [],
  subIngredientsHasMore: false,
  subIngredientsAfterIndex: null,
};

export const INGREDIENT_STATUS = {
  [STATUS.IN_PROGRESS]: STATUS.IN_PROGRESS,
  [STATUS.APPROVED]: STATUS.APPROVED,
};

export const INGREDIENT_TYPE = {
  BASE: 'base',
  SUB: 'sub',
};

// TODO: Should this come from API? What's the preferred format? Enum, object, array?
export const DONENESS_LEVELS = [
  'Seared',
  'Rare',
  'Medium Rare',
  'Medium',
  'Medium Well',
  'Well Done',
  'Barbecue Tender',
];
