import { makeStyles } from '@material-ui/core';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  signInText: {
    height: '28px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.17,
    letterSpacing: -0.06,
    color: theme.palette.text.primary,
    marginLeft: '6px',
  },
  card: {
    position: 'absolute',
    top: '20%',
    width: '95%',
    maxWidth: '517px',
    height: '230px',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.paper,
    opacity: '0.95',
    boxShadow: '1px 1px 2px 1px #aaa',
  },
  button: {
    width: '100%',
    height: '42px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
  },
  logo: {
    width: '100px',
    height: '25px',
  },
  lock: {
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    left: '47px',
    top: '-25px',
  },
}));
