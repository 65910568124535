/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAllAppliances = `
  query GetAllAppliances {
    appliances {
      appliance_id
      name
    }
  }
`;

export const getAllActions = /* GraphQL */ `
  query GetAllActions($input: GetAllActionsInput) {
    getAllActions(input: $input) {
      code
      data {
        action_id
        command
        created_at
        created_by
        description
        name
        order
        photo
        updated_at
        updated_by
        video
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllActionsModels = /* GraphQL */ `
  query GetAllActionsModels($input: GetAllActionsModelsInput) {
    getAllActionsModels(input: $input) {
      code
      data {
        action {
          action_id
          command
          created_at
          created_by
          description
          name
          order
          photo
          updated_at
          updated_by
          video
        }
        action_id
        action_model_id
        created_at
        created_by
        model_number
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllActionsProperties = /* GraphQL */ `
  query GetAllActionsProperties($input: GetAllActionsPropertiesInput) {
    getAllActionsProperties(input: $input) {
      code
      data {
        action_id
        action_property_id
        created_at
        created_by
        order
        property {
          code
          created_at
          created_by
          name
          ui_type
          list_data
          property_id
          updated_at
          updated_by
        }
        property_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllActiveRecipes = /* GraphQL */ `
  query GetAllActiveRecipes($input: GetAllActiveRecipesInput) {
    getAllActiveRecipes(input: $input) {
      code
      data {
        active_recipe_id
        created_at
        created_by
        env
        recipe_id
        updated_at
        updated_by
        versioned_recipe_url
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllCategories = /* GraphQL */ `
  query GetAllCategories {
    getAllCategories {
      code
      data {
        category_id
        created_at
        created_by
        name
        order
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllFractions = /* GraphQL */ `
  query GetAllFractions {
    getAllFractions {
      code
      data {
        created_at
        created_by
        fraction_id
        updated_at
        updated_by
        value
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllHowtos = /* GraphQL */ `
  query GetAllHowtos {
    getAllHowtos {
      code
      data {
        created_at
        created_by
        video_id
        name
        order
        photo
        updated_at
        updated_by
        video
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllIngredientCategories = /* GraphQL */ `
  query GetAllIngredientCategories {
    getAllIngredientCategories {
      code
      data {
        created_at
        created_by
        ingredient_category_id
        name
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllIngredientSections = /* GraphQL */ `
  query GetAllIngredientSections {
    getAllIngredientSections {
      code
      data {
        created_at
        created_by
        ingredient_section_id
        name
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllIngredients = /* GraphQL */ `
  query GetAllIngredients($input: GetAllIngredientsInput) {
    getAllIngredients(input: $input) {
      code
      data {
        after
        has_more
        ingredients {
          category
          created_at
          created_by
          ingredient_id
          name
          name_plural
          parent_id
          photo
          prep
          pre
          post
          status
          type
          unit_id
          updated_at
          updated_by
          video
          weight
          ingredient_doneness {
            image
            ingredient_doneness_id
            ingredient_id
            order
            temperature_guide_id
            temperature_guide {
              doneness
              doneness_order
              icon
              is_usda_approved
              name
              order
              remove_temp
              short_doneness
              short_name
              target_temp
              temperature_guide_id
            }
          }
        }
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllModels = /* GraphQL */ `
  query GetAllModels($input: GetAllModelsInput) {
    getAllModels(input: $input) {
      code
      data {
        category_id
        created_at
        created_by
        icon
        model_number
        name
        picture
        short_code
        short_id
        type
        updated_at
        updated_by
        user_guide
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllPropertiesChoices = /* GraphQL */ `
  query GetAllPropertiesChoices($input: GetAllPropertiesChoicesInput) {
    getAllPropertiesChoices(input: $input) {
      code
      data {
        choice {
          choice_id
          code
          created_at
          created_by
          name
          updated_at
          updated_by
          properties
        }
        choice_id
        created_at
        created_by
        order
        property_choice_id
        property_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllRecipeDevelopers = /* GraphQL */ `
  query GetAllRecipeDevelopers($input: GetRecipeDevelopersInput) {
    getAllRecipeDevelopers(input: $input) {
      code
      data {
        created_at
        created_by
        email
        email_verified
        first_name
        groups
        identities
        last_name
        phone_number
        phone_number_verified
        updated_at
        updated_by
        user_id
      }
      message
      statusCode
      success
    }
  }
`;

export const getPropertiesByCode = /* GraphQL */ `
  query GetPropertiesByCode($code: [String]) {
    properties(code: $code) {
      property_id
      code
      name
      ui_type
      list_data
    }
  }
`;

export const getAllRecipes = /* GraphQL */ `
  query GetAllRecipes($input: GetAllRecipesInput) {
    getAllRecipes(input: $input) {
      code
      data {
        after
        has_more
        recipes {
          active_time
          cover_photo
          cover_video
          created_at
          created_by
          description
          device_used
          devices_used {
            created_at
            created_by
            model_number
            model_recipe_id
            recipe_id
            updated_at
            updated_by
            model {
              category {
                category_id
                name
                icon
                picture
                type
              }
              category_id
              created_at
              created_by
              icon
              model_number
              name
              picture
              short_code
              short_id
              type
              updated_at
              updated_by
              user_guide
            }
          }
          difficulty_level
          has_precooked_ingredients
          howtos {
            created_at
            created_by
            video_id
            howto_recipe_id
            name
            order
            recipe_id
            updated_at
            updated_by
          }
          ingredient_sections {
            created_at
            created_by
            ingredient_section_id
            ingredient_section_recipe_id
            name
            order
            recipe_id
            updated_at
            updated_by
          }
          ingredients {
            amount
            created_at
            created_by
            fraction
            ingredient_id
            ingredient_recipe_id
            ingredient_section_recipe_id
            is_main
            name
            name_plural
            optional_info
            order
            photo
            recipe_id
            step_id
            unit_id
            updated_at
            updated_by
            video
            weight
          }
          linked_recipes {
            created_at
            created_by
            linked_recipe_id
            source_linked_recipe_id
            source_recipe_id
            updated_at
            updated_by
          }
          name
          recipe_developer_id
          recipe_id
          recipe_number
          sections {
            created_at
            created_by
            name
            order
            model_number
            duration
            recipe_id
            section_id
            section_recipe_id
            updated_at
            updated_by
          }
          status
          steps {
            actions
            button_text
            conditions
            created_at
            created_by
            description
            has_timer
            photo
            recipe_id
            section_recipe_id
            smart
            step_id
            step_number
            stop_actions
            updated_at
            updated_by
            video
          }
          tags {
            created_at
            created_by
            name
            order
            recipe_id
            tag_id
            tag_recipe_id
            type
            updated_at
            updated_by
          }
          tools {
            created_at
            created_by
            name
            order
            recipe_id
            tool_id
            tool_recipe_id
            updated_at
            updated_by
          }
          total_likes
          total_time
          updated_at
          updated_by
          yield_number
          yield_type
        }
      }
      message
      statusCode
      success
    }
  }
`;

export const getRecipes = /* GraphQL */ `
  query GetRecipes(
    $after: String
    $created_by: ID
    $disable_paging: Boolean
    $limit: Int
    $recipe_number: Int
    $status: String
  ) {
    recipes(
      after: $after
      created_by: $created_by
      disable_paging: $disable_paging
      limit: $limit
      recipe_number: $recipe_number
      status: $status
    ) {
      after
      has_more
      total
      recipes {
        active_time
        cover_photo
        cover_video
        created_at
        created_by
        description
        device_used
        difficulty_level
        has_precooked_ingredients
        howtos {
          created_at
          created_by
          video_id
          howto_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredient_sections {
          created_at
          created_by
          ingredient_section_id
          ingredient_section_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredients {
          amount
          created_at
          created_by
          fraction
          ingredient_id
          ingredient_recipe_id
          ingredient_section_recipe_id
          is_main
          name
          name_plural
          optional_info
          order
          photo
          recipe_id
          step_id
          unit_id
          updated_at
          updated_by
          video
          weight
        }
        linked_recipes {
          created_at
          created_by
          linked_recipe_id
          source_linked_recipe_id
          source_recipe_id
          updated_at
          updated_by
        }
        name
        recipe_developer_id
        recipe_id
        recipe_number
        sections {
          created_at
          created_by
          name
          order
          model_number
          duration
          recipe_id
          section_id
          section_recipe_id
          updated_at
          updated_by
        }
        status
        steps {
          actions
          button_text
          conditions
          created_at
          created_by
          description
          has_timer
          photo
          recipe_id
          section_recipe_id
          smart
          step_id
          step_number
          stop_actions
          updated_at
          updated_by
          video
        }
        tags {
          created_at
          created_by
          name
          order
          recipe_id
          tag_id
          tag_recipe_id
          type
          updated_at
          updated_by
        }
        tools {
          created_at
          created_by
          name
          order
          recipe_id
          tool_id
          tool_recipe_id
          updated_at
          updated_by
        }
        total_likes
        total_time
        updated_at
        updated_by
        yield_number
        yield_type
      }
    }
  }
`;

export const getAllSections = /* GraphQL */ `
  query GetAllSections {
    getAllSections {
      code
      data {
        created_at
        created_by
        name
        section_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllTags = /* GraphQL */ `
  query GetAllTags($input: GetAllTagsInput) {
    getAllTags(input: $input) {
      code
      data {
        created_at
        created_by
        name
        show_filter
        tag_id
        type
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllTools = /* GraphQL */ `
  query GetAllTools {
    getAllTools {
      code
      data {
        created_at
        created_by
        name
        photo
        tool_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllUnits = /* GraphQL */ `
  query GetAllUnits {
    getAllUnits {
      code
      data {
        abbreviation
        created_at
        created_by
        name
        unit_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllVideoCategories = /* GraphQL */ `
  query GetAllVideoCategories {
    getAllVideoCategories {
      code
      data {
        created_at
        created_by
        name
        updated_at
        updated_by
        video_category_id
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllVideos = /* GraphQL */ `
  query GetAllVideos($input: GetAllVideosInput) {
    getAllVideos(input: $input) {
      code
      data {
        after
        has_more
        videos {
          video_category_id
          created_at
          created_by
          name
          photo
          status
          updated_at
          updated_by
          video
          video_id
        }
      }
      message
      statusCode
      success
    }
  }
`;
export const getAllYieldTypes = /* GraphQL */ `
  query GetAllYieldTypes {
    getAllYieldTypes {
      code
      data {
        created_at
        created_by
        name
        updated_at
        updated_by
        yield_id
      }
      message
      statusCode
      success
    }
  }
`;
export const getIngredient = /* GraphQL */ `
  query GetIngredient($input: GetIngredientInput!) {
    getIngredient(input: $input) {
      code
      data {
        category
        created_at
        created_by
        ingredient_id
        name
        name_plural
        parent_id
        photo
        prep
        pre
        post
        status
        type
        unit_id
        updated_at
        updated_by
        video
        weight
        ingredient_doneness {
          image
          ingredient_doneness_id
          ingredient_id
          order
          temperature_guide_id
          temperature_guide {
            doneness
            doneness_order
            icon
            is_usda_approved
            name
            order
            remove_temp
            short_doneness
            short_name
            target_temp
            temperature_guide_id
          }
        }
      }
      message
      statusCode
      success
    }
  }
`;
export const getRecipe = /* GraphQL */ `
  query GetRecipe($input: GetRecipeInput!) {
    getRecipe(input: $input) {
      code
      data {
        active_time
        cover_photo
        cover_video
        cover_trailer
        created_at
        created_by
        description
        device_used
        devices_used {
          created_at
          created_by
          model_number
          model_recipe_id
          recipe_id
          updated_at
          updated_by
          model {
            category {
              category_id
              name
              icon
              picture
              type
            }
            category_id
            created_at
            created_by
            icon
            model_number
            name
            picture
            short_code
            short_id
            type
            updated_at
            updated_by
            user_guide
          }
        }
        difficulty_level
        appliance_id
        has_precooked_ingredients
        howtos {
          created_at
          created_by
          video_id
          howto_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredient_sections {
          created_at
          created_by
          ingredient_section {
            created_at
            created_by
            ingredient_section_id
            name
            updated_at
            updated_by
          }
          ingredient_section_id
          ingredient_section_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredients {
          amount
          created_at
          created_by
          fraction
          ingredient {
            category
            created_at
            created_by
            ingredient_id
            name
            name_plural
            parent_id
            photo
            prep
            pre
            post
            status
            type
            unit_id
            updated_at
            updated_by
            video
            weight
            ingredient_doneness {
              image
              ingredient_doneness_id
              ingredient_id
              order
              temperature_guide_id
              temperature_guide {
                doneness
                doneness_order
                icon
                is_usda_approved
                name
                order
                remove_temp
                short_doneness
                short_name
                target_temp
                temperature_guide_id
              }
            }
          }
          ingredient_id
          ingredient_recipe_id
          ingredient_section_recipe_id
          is_main
          name
          name_plural
          optional_info
          order
          photo
          recipe_id
          step_id
          unit {
            abbreviation
            created_at
            created_by
            name
            unit_id
            updated_at
            updated_by
          }
          unit_id
          updated_at
          updated_by
          video
          weight
        }
        linked_recipes {
          created_at
          created_by
          linked_recipe {
            active_time
            cover_photo
            cover_video
            created_at
            created_by
            description
            device_used
            difficulty_level
            has_precooked_ingredients
            name
            recipe_developer_id
            recipe_id
            recipe_number
            status
            total_likes
            total_time
            updated_at
            updated_by
            yield_number
            yield_type
          }
          linked_recipe_id
          source_linked_recipe_id
          source_recipe {
            active_time
            cover_photo
            cover_video
            created_at
            created_by
            description
            device_used
            difficulty_level
            has_precooked_ingredients
            name
            recipe_developer_id
            recipe_id
            recipe_number
            status
            total_likes
            total_time
            updated_at
            updated_by
            yield_number
            yield_type
          }
          source_recipe_id
          updated_at
          updated_by
        }
        name
        recipe_developer_id
        recipe_id
        recipe_number
        sections {
          created_at
          created_by
          name
          order
          recipe_id
          model_number
          duration
          section {
            created_at
            created_by
            name
            section_id
            updated_at
            updated_by
          }
          section_id
          section_recipe_id
          updated_at
          updated_by
        }
        status
        steps {
          actions
          button_text
          conditions
          created_at
          created_by
          description
          has_timer
          photo
          recipe_id
          section_recipe_id
          smart
          step_id
          step_number
          stop_actions
          updated_at
          updated_by
          video
        }
        tags {
          created_at
          created_by
          name
          order
          recipe_id
          tag {
            created_at
            created_by
            name
            show_filter
            tag_id
            type
            updated_at
            updated_by
          }
          tag_id
          tag_recipe_id
          type
          updated_at
          updated_by
        }
        tools {
          created_at
          created_by
          name
          order
          recipe_id
          tool {
            created_at
            created_by
            name
            photo
            tool_id
            updated_at
            updated_by
          }
          tool_id
          tool_recipe_id
          updated_at
          updated_by
        }
        total_likes
        total_time
        updated_at
        updated_by
        yield_number
        yield_type
        profiles {
          recipe_id
          user_id
          profile_recipe_id
          profile {
            username
          }
        }
      }
      message
      statusCode
      success
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($input: GetVideoInput!) {
    getVideo(input: $input) {
      code
      data {
        video_category_id
        created_at
        created_by
        name
        photo
        status
        updated_at
        updated_by
        video
        video_id
      }
      message
      statusCode
      success
    }
  }
`;
export const getNotificationCategories = /* GraphQL */ `
  query GetNotificationCategories {
    notificationCategories {
      audio_clip
      audio_clips
      based_on
      condition_key
      condition_operand
      created_at
      created_by
      description
      display_text
      has_push_notification
      has_smartbar_notification
      notification_category
      notification_category_id
      reminder_time
      short_display_text
      updated_at
      updated_by
    }
  }
`;

export const getTemperatureGuides = /* GraphQL */ `
  query GetTemperatureGuides {
    temperatureGuide {
      doneness
      doneness_order
      icon
      is_usda_approved
      name
      order
      remove_temp
      short_doneness
      short_name
      target_temp
      temperature_guide_id
    }
  }
`;

export const getTickets = /* GraphQL */ `
  query GetTickets(
    $after: String
    $content_type: ModerationContentType
    $limit: Int
    $status: TicketStatus
  ) {
    tickets(
      after: $after
      content_type: $content_type
      limit: $limit
      status: $status
    ) {
      after
      has_more
      total
      tickets {
        ticket_id
        ticket_number
        status
        reports_count
        created_at
        content {
          ... on Comment {
            comment_id
            message
            media {
              url
            }
            is_edited
            deleted_at
            status
          }
        }
        report_types {
          name
        }
      }
    }
  }
`;

export const getTicket = /* GraphQL */ `
  query GetTicket($ticket_id: ID!) {
    ticket(ticket_id: $ticket_id) {
      ticket_id
      ticket_number
      status
      reports_count
      created_at
      content {
        ... on Comment {
          comment_id
          message
          media {
            url
          }
          is_edited
          deleted_at
          status
          created_at
          user_profile {
            user_id
            username
            display_picture
          }
          recipe {
            recipe_id
            name
            cover_photo
          }
        }
      }
      reports {
        report_id
        message
        created_at
        report_type {
          name
        }
        user_profile {
          user_id
          username
          picture
        }
      }
      actions {
        ticket_action_id
        type
        action_taken
        message
        created_at
        resolved_by {
          first_name
          last_name
        }
      }
    }
  }
`;

export const getRelatedTickets = /* GraphQL */ `
  query GetRelatedTickets($ticket_id: ID!) {
    ticketGetRelatedTickets(ticket_id: $ticket_id) {
      ticket_id
      ticket_number
      status
      reports {
        report_id
        message
        created_at
        report_type {
          name
        }
        user_profile {
          user_id
          username
          display_picture
        }
      }
      actions {
        ticket_action_id
        type
        action_taken
        message
        created_at
        resolved_by {
          first_name
          last_name
        }
      }
    }
  }
`;

export const getSectionRecipeModels = /* GraphQL */ `
  query GetSectionRecipeModels($section_recipe_id: ID) {
    modelsSectionsRecipes(section_recipe_id: $section_recipe_id) {
      model_section_recipe_id
      section_recipe_id
      section_recipe {
        section_recipe_id
      }
      model_number
      model {
        model_number
      }
      created_by
      updated_by
      created_at
      updated_at
    }
  }
`;

export const getProfiles = /* GraphQL */ `
  query GetProfiles($disable_paging: Boolean, $type: ProfileType) {
    profiles(disable_paging: $disable_paging, type: $type) {
      created_at
      display_picture
      picture
      profile_default_image {
        path
      }
      type
      updated_at
      use_default_picture
      user_id
      username
    }
  }
`;

export const searchRecipes = /* GraphQL */ `
  query SearchRecipes(
    $search_text: String
    $status: [String]
    $difficulty_levels: [String]
    $device_category_id: [ID]
    $device_used: [String]
    $tags: [String]
    $meals: [String]
    $diets: [String]
    $cuisines: [String]
    $conveniences: [String]
    $occasions: [String]
    $appliances: [String]
    $size: Int
    $from: Int
    $sort_by: String
  ) {
    searchRecipes(
      search_text: $search_text
      status: $status
      difficulty_levels: $difficulty_levels
      device_category_id: $device_category_id
      device_used: $device_used
      tags: $tags
      meals: $meals
      diets: $diets
      cuisines: $cuisines
      conveniences: $conveniences
      occasions: $occasions
      appliances: $appliances
      size: $size
      from: $from
      sort_by: $sort_by
    ) {
      hits {
        total {
          value
        }
        hits {
          _source {
            ... on Recipe {
              recipe_id
              name
              recipe_number
              cover_photo
              status
              total_time
              devices_used {
                model_number
              }
              device_used
              profiles {
                profile {
                  username
                  display_picture
                }
              }
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`;

export const searchIngredients = /* GraphQL */ `
  query SearchIngredients(
    $search_text: String
    $status: [String!]
    $categories: [String!]
    $size: Int
    $from: Int
  ) {
    searchIngredients(
      search_text: $search_text
      status: $status
      categories: $categories
      size: $size
      from: $from
    ) {
      hits {
        total {
          value
        }
        hits {
          _source {
            ingredient_id
            name
            pre
            name_plural
            post
            category
            status
            video
            photo
            created_at
            updated_at
          }
        }
      }
    }
  }
`;

export const searchIngredientsSuggest = /* GraphQL */ `
  query SearchIngredientsSuggest(
    $search_text: String
    $status: [String!]
    $categories: [String!]
    $size: Int
  ) {
    searchIngredientsSuggest(
      search_text: $search_text
      status: $status
      categories: $categories
      size: $size
    ) {
      suggest {
        ingredients {
          text
          offset
          length
          options {
            text
            _id
          }
        }
      }
    }
  }
`;

export const getModels = /* GraphQL */ `
  query GetModels($type: String) {
    models(
      type: $type
    ) {
        category_id
        created_at
        created_by
        icon
        model_number
        name
        picture
        short_code
        short_id
        type
        updated_at
        updated_by
        user_guide
        category {
          category_id
          name
          icon
          picture
          type
          thing_category_name
        }
    }
  }
`;