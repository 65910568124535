import { makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
  iconButton: {
    color: theme.palette.text.primary,
  },
}));
