import { memo } from 'react';

import { Helmet } from 'react-helmet';

import Loading from 'src/components/Loading';

import { PageProps } from './types';

const Page = memo(
  ({ children, title = '', loading = false, ...rest }: PageProps) => (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {loading && <Loading withSidebar />}
      {children}
    </div>
  ),
);

export default Page;
