import { useMemo, useState, useCallback, useEffect, useRef } from 'react';

import camelcaseKeys from 'camelcase-keys';
import { keyBy as _keyBy } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import { GET_MODELS } from 'src/apollo';
import { SearchItem } from 'src/components/SearchCard/types';
import { DEVICE_MODEL_TYPES, STATUS_VALUES } from 'src/constants';
import { useGetAllQueryConfig, useLazyQuery } from 'src/hooks';
import {
  isDeprecatedPaginatedQueryResponseTypeGuard,
  isPaginatedQueryResponseTypeGuard,
  isSearchQueryResponseTypeGuard,
} from 'src/hooks/apollo/types';
import { GetModelsQuery } from 'src/types/camelCaseApi';
import { CheckedItemsIndex, FilterTabType } from 'src/types/global';

import { UseListViewPropsType } from './types';

const DEFAULT_LIMIT = 20;

const DEFAULT_SORTING = '_score-desc,updated_at-desc';

const useListView = ({
  query,
  queryName,
  queryParams,
  searchKey,
  itemName,
  idKey,
  shouldGetModels = false,
}: UseListViewPropsType) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<SearchItem[]>([]);
  const [selectedTab, setSelectedTab] = useState<FilterTabType>('all');
  const [selectedFilter, setSelectedFilter] = useState<string>('Recent');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [items, setItems] = useState<Array<Record<string, unknown>>>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [afterIndex, setAfterIndex] = useState<string | null>(null);
  const [checkedItems, setCheckedItems] = useState<CheckedItemsIndex>({});
  const [optionSelectedItem, setOptionSelectedItem] =
    useState<Record<string, unknown> | null>(null);

  const current = useRef<number>(0);
  const total = useRef<number>(0);

  const { getOptions, fetchMoreOptions } = useGetAllQueryConfig({
    afterIndex,
    tab: selectedTab,
    inputVariables: queryParams,
  });
  const status = getOptions.variables.input?.status || STATUS_VALUES;
  const searchParams = selectedItems.reduce((result, item) => {
    return {
      ...result,
      [item.filterType]: item.values,
    };
  }, {});

  const [getAllItems, { loading: getAllItemsLoading, fetchMore, data }] =
    useLazyQuery({
      query,
      queryName,
      // Old queries will have the params in the input object, whereas newer ones have each param in the function signature.
      // Spreading the input object into the variables lets us handle both cases.
      // Once those queries are updated, we can change this back to 'options: getOptions'.
      options: {
        ...getOptions,
        variables: {
          ...getOptions.variables,
          ...getOptions.variables.input,
          ...searchParams,
          status,
          size: DEFAULT_LIMIT,
          sort_by: DEFAULT_SORTING,
        },
      },
    });

  const isFiltered =
    (searchKey && searchValue.length > 0) ||
    Object.keys(searchParams).length > 0;

  const debouncedGetAllItems = useDebouncedCallback(() => {
    const variables: Record<string, unknown> = {};

    if (searchKey && searchValue.length > 0) {
      variables[searchKey] = searchValue;
    }
    getAllItems({ variables });
  }, 500);

  const getModelsQueryOptions = useMemo(
    () => ({
      variables: {
        type: DEVICE_MODEL_TYPES.APPLIANCE,
      },
    }),
    [],
  );

  const [
    getAllModels,
    { loading: getAllModelsLoading = false, data: getAllModelsData },
  ] = useLazyQuery<GetModelsQuery>({
    query: GET_MODELS,
    queryName: 'models',
    options: getModelsQueryOptions,
  });
  const { models } = getAllModelsData || {};

  const modelsObject = useMemo(() => _keyBy(models, 'modelNumber'), [models]);

  useEffect(() => {
    debouncedGetAllItems.callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(
    () => () => {
      debouncedGetAllItems.flush();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (shouldGetModels) getAllModels();
  }, [getAllModels, shouldGetModels]);

  useEffect(() => {
    setItems([]);
    setHasMore(false);
    setAfterIndex(null);
  }, [selectedTab]);

  useEffect(() => {
    let localItems: Array<Record<string, unknown> | null> = [];
    let localHasMore = false;
    let after = null;

    if (
      isSearchQueryResponseTypeGuard(data, 'searchRecipes') ||
      isSearchQueryResponseTypeGuard(data, 'searchIngredients')
    ) {
      const key = Object.keys(data)[0];
      localItems = data[key].hits.hits.map((hit) => hit.source);
      localHasMore = data[key].hits.total.value > DEFAULT_LIMIT;
      after = localItems.length.toString();
      current.current = localItems.length;
      total.current = data[key].hits.total.value;
    } else if (isPaginatedQueryResponseTypeGuard(data)) {
      localItems = data[queryName]?.[itemName] || [];
      localHasMore = data[queryName]?.hasMore || false;
      after = data[queryName]?.after || null;
    } else if (isDeprecatedPaginatedQueryResponseTypeGuard(data)) {
      localItems = data[queryName]?.data?.[itemName] || [];
      localHasMore = data[queryName]?.data?.hasMore || false;
      after = data[queryName]?.data?.after || null;
    }

    if (localItems) {
      const filteredItems = localItems.filter((el) => el) as Array<
        Record<string, unknown>
      >;
      setItems(filteredItems);
    }
    localHasMore && setHasMore(localHasMore);
    after && setAfterIndex(after);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleFetchMore = useCallback(async () => {
    if (!fetchMore) return;

    const variables: Record<string, unknown> = {
      ...fetchMoreOptions.variables,
      after: afterIndex,
      from: parseInt(afterIndex || '0'),
    };

    if (searchKey && searchValue.length > 0) {
      variables[searchKey] = searchValue;
    }

    const { data: fetchMoreData } = await fetchMore({
      ...fetchMoreOptions,
      variables: {
        ...variables,
        status,
      },
    });

    let localItems: Array<Record<string, unknown> | null> = [];
    let localHasMore = false;
    let after = null;

    if (
      isSearchQueryResponseTypeGuard(fetchMoreData, 'searchRecipes') ||
      isSearchQueryResponseTypeGuard(fetchMoreData, 'searchIngredients')
    ) {
      const key = Object.keys(fetchMoreData)[0];
      localItems = fetchMoreData[key].hits.hits.map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Fetch more query doesn't convert keys to camel case
        // eslint-disable-next-line no-underscore-dangle
        (hit) => hit._source,
      );

      current.current += localItems.length;
      total.current = fetchMoreData[key].hits.total.value;
      localHasMore = total.current > current.current;
      after = current.current;
    } else if (isPaginatedQueryResponseTypeGuard(fetchMoreData)) {
      localItems = fetchMoreData[queryName]?.[itemName] || [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Fetch more query doesn't convert keys to camel case - TODO: update this.
      localHasMore = fetchMoreData[queryName]?.has_more || false;
      after = fetchMoreData[queryName]?.after || null;
    } else if (isDeprecatedPaginatedQueryResponseTypeGuard(fetchMoreData)) {
      localItems = fetchMoreData[queryName]?.data?.[itemName] || [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Fetch more query doesn't convert keys to camel case - TODO: update this.
      localHasMore = fetchMoreData[queryName]?.data?.has_more || false;
      after = fetchMoreData[queryName]?.data?.after || null;
    }

    if (localItems) {
      const camelizedLocalItems = camelcaseKeys(localItems, { deep: true });
      const filteredItems = camelizedLocalItems.filter((el) => el) as Array<
        Record<string, unknown>
      >;
      setItems([...items, ...filteredItems]);
      setHasMore(Boolean(localHasMore));
      setAfterIndex(after ? after.toString() : null);
    }
  }, [
    afterIndex,
    fetchMore,
    fetchMoreOptions,
    itemName,
    items,
    queryName,
    searchKey,
    searchValue,
    status,
  ]);

  const checkboxVisible: boolean = useMemo(
    () => checkedItems && Object.keys(checkedItems).length > 0,
    [checkedItems],
  );

  const handleCheckCard = useCallback(
    (itemId: string, checked: boolean) => {
      let newCheckedItems = {};
      if (checked) {
        newCheckedItems = { ...checkedItems, [itemId]: true };
      } else {
        const { [itemId]: itemToUncheck, ...rest } = checkedItems;
        newCheckedItems = rest;
      }

      setCheckedItems(newCheckedItems);
    },
    [checkedItems],
  );

  const optionVisible = Boolean(optionSelectedItem);

  const handleOptionSelect = useCallback(
    (item) => {
      setOptionSelectedItem(
        optionSelectedItem?.[idKey] !== item?.[idKey] ? item : null,
      );
    },
    [idKey, optionSelectedItem],
  );

  return {
    getAllItems,
    getAllItemsLoading,
    items,
    hasMore,
    modelsObject,
    getAllModelsLoading,
    handleFetchMore,
    checkedItems,
    setCheckedItems,
    checkboxVisible,
    handleCheckCard,
    optionVisible,
    optionSelectedItem,
    handleOptionSelect,
    selectedItems,
    setSelectedItems,
    searchValue,
    setSearchValue,
    selectedTab,
    setSelectedTab,
    selectedFilter,
    setSelectedFilter,
    isDrawerOpen,
    setIsDrawerOpen,
    isFiltered,
    current: current.current,
    total: total.current,
    searchParams,
  };
};

export default useListView;
