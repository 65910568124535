import { useCallback } from 'react';

import { Box, Container, Button, Typography } from '@material-ui/core';
import { Lock as LockIcon } from 'react-feather';

import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import { useAmplify } from 'src/hooks';

import { useStyles } from './index.style';

const LoginView = () => {
  const classes = useStyles();
  const { signIn } = useAmplify();

  const handleSignIn = useCallback(() => {
    signIn();
  }, [signIn]);

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box className={classes.card}>
            <Box
              className={classes.lock}
              width={50}
              height={50}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LockIcon size={22} />
            </Box>
            <Box mt="47px" ml="47px" display="flex" alignItems="center">
              <Logo fullText className={classes.logo} />
              <Typography variant="h3" className={classes.signInText}>
                Console
              </Typography>
            </Box>
            <Box mx="47px" my="30px">
              <Button
                onClick={handleSignIn}
                variant="contained"
                className={classes.button}
              >
                SIGN IN
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default LoginView;
