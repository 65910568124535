import { Video } from 'src/types/video';

import { STATUS } from '../global';

export const VIDEO_PAGE_TABS = [
  { value: 'video', label: 'Video' },
  { value: 'review', label: 'Review' },
  // { value: 'activity', label: 'Activity' }, //TODO: Uncomment when the feature is implemented
];

export const VIDEO_STATUS = {
  [STATUS.IN_PROGRESS]: STATUS.IN_PROGRESS,
  [STATUS.IN_REVIEW]: STATUS.IN_REVIEW,
  [STATUS.APPROVED]: STATUS.APPROVED,
};

export const VIDEO_FORM_INITIAL_VALUES: Video = {
  typename: 'Video',
  videoId: null,
  category: null,
  name: null,
  photo: null,
  video: null,
  status: null,
  createdBy: null,
  updatedBy: null,
  createdAt: null,
  updatedAt: null,
  videoCategoryId: null,
};

export const VIDEO_CATEGORY = {
  HOW_TO: 'How To',
  INGREDIENT_PREP: 'Ingredient Prep',
};
