import { useEffect, useMemo, useState } from 'react';

import { isEmpty as _isEmpty } from 'lodash';

import { CONTENT_TYPES, REQUIRED_FIELDS } from 'src/constants';
import {
  PERMISSIONS,
  PUSH_TO_REQUIRED_PERMISSIONS,
} from 'src/constants/global';

import usePermissions from '../usePermissions';
import {
  isIngredientTypeGuard,
  isRecipeTypeGuard,
  isVideoTypeGuard,
  UseSaveToType,
} from './types';

const isValidValue = (value: unknown) => {
  if (value === null || value === undefined) return false;
  if (typeof value === 'number') return true;
  return !_isEmpty(value);
};

const useSaveTo = (props: UseSaveToType) => {
  const [isAllowed, setIsAllowed] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const permissions = usePermissions(
    {
      [props.type]: [
        PERMISSIONS.CREATE_UPDATE,
        PERMISSIONS.PUSH_TO_DEV,
        PERMISSIONS.PUSH_TO_BETA,
        PERMISSIONS.PUSH_TO_PROD,
      ],
    },
    props.values.createdBy,
  );

  const hasPushPermission = Boolean(
    permissions[props.type]?.[PUSH_TO_REQUIRED_PERMISSIONS[props.status]],
  );

  const hasUpdatePermission = Boolean(permissions[props.type]?.CREATE_UPDATE);

  const requiredFields = useMemo(
    () => REQUIRED_FIELDS[props.type][props.status] || [],
    [props.status, props.type],
  );

  useEffect(() => {
    if (!requiredFields) return;

    let isRequiredFilled = false;
    if (
      isRecipeTypeGuard(requiredFields) &&
      props.type === CONTENT_TYPES.RECIPE
    ) {
      isRequiredFilled = requiredFields.every(
        (key) => isValidValue(props.values[key]),
      );
      setMissingFields(
        requiredFields.filter((key) => !isValidValue(props.values[key])),
      );
    } else if (
      isIngredientTypeGuard(requiredFields) &&
      props.type === CONTENT_TYPES.INGREDIENT
    ) {
      isRequiredFilled = requiredFields.every(
        (key) => !_isEmpty(props.values[key]),
      );
      setMissingFields(
        requiredFields.filter((key) => _isEmpty(props.values[key])),
      );
    } else if (
      isVideoTypeGuard(requiredFields) &&
      props.type === CONTENT_TYPES.VIDEO
    ) {
      isRequiredFilled = requiredFields.every(
        (key) => !_isEmpty(props.values[key]),
      );
      setMissingFields(
        requiredFields.filter((key) => _isEmpty(props.values[key])),
      );
    }
    setIsAllowed(Boolean(isRequiredFilled));
  }, [props.status, props.type, props.values, requiredFields]);

  return {
    hasUpdatePermission,
    hasPushPermission,
    isRequiredFilled: isAllowed,
    requiredFields,
    missingFields,
  };
};

export default useSaveTo;
