import { useCallback, useEffect, useState } from 'react';

import { PLACEHOLDER_VIDEO_THUMBNAIL, PLACEHOLDER_IMAGE } from 'src/constants';
import { resizedImageUrl } from 'src/utils';

import { UsePhotoProps } from './types';

const usePhoto = ({
  image: originalImage,
  type = 'photo',
  size = '256',
}: UsePhotoProps) => {
  const initializeImage = useCallback(() => {
    if (type === 'video') return PLACEHOLDER_VIDEO_THUMBNAIL;

    if (type === 'thumbnail') return PLACEHOLDER_VIDEO_THUMBNAIL;

    return PLACEHOLDER_IMAGE;
  }, [type]);

  const [image, setImage] = useState(initializeImage);

  useEffect(() => {
    if (originalImage && !originalImage.includes('placeholder.')) {
      const resizedImage = resizedImageUrl({ imageUrl: originalImage, size });
      setImage(resizedImage);
    } else if (!originalImage) {
      const placeholderImage = initializeImage();

      setImage(placeholderImage);
    }
  }, [originalImage, type, size, initializeImage]);

  return image;
};

export default usePhoto;
