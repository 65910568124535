import {
  LOGO_IMAGE,
  CHEFIQ_LOGO_LIGHT_IMAGE,
  CHEFIQ_LOGO_DARK_IMAGE,
} from 'src/constants';
import { useSettings } from 'src/hooks';

import { LogoProps } from './types';

const Logo = ({ className, fullText }: LogoProps) => {
  const { settings } = useSettings();

  if (!fullText)
    return <img alt="Logo" src={LOGO_IMAGE} width="40px" className={className} />;

  const logo =
    settings.theme === 'LIGHT'
      ? CHEFIQ_LOGO_LIGHT_IMAGE
      : CHEFIQ_LOGO_DARK_IMAGE;

  return <img alt="CHEFiQ Logo" src={logo} className={className} />;
};

export default Logo;
