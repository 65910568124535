import { CONTENT_TYPES, StatusType } from 'src/constants';
import { IngredientProps } from 'src/types/ingredient';
import { RecipeProps } from 'src/types/recipe';
import { Video } from 'src/types/video';

export type UseSaveToType = RecipeSaveTo | IngredientSaveTo | VideoSaveTo;

interface RecipeSaveTo {
  values: RecipeProps;
  type: typeof CONTENT_TYPES.RECIPE;
  status: StatusType;
}

interface IngredientSaveTo {
  values: IngredientProps;
  type: typeof CONTENT_TYPES.INGREDIENT;
  status: StatusType;
}

interface VideoSaveTo {
  values: Video;
  type: typeof CONTENT_TYPES.VIDEO;
  status: StatusType;
}

export const isRecipeTypeGuard = (
  el: (keyof RecipeProps)[] | (keyof IngredientProps)[] | (keyof Video)[],
): el is (keyof RecipeProps)[] => Array.isArray(el);

export const isIngredientTypeGuard = (
  el: (keyof RecipeProps)[] | (keyof IngredientProps)[] | (keyof Video)[],
): el is (keyof IngredientProps)[] => Array.isArray(el);

export const isVideoTypeGuard = (
  el: (keyof RecipeProps)[] | (keyof IngredientProps)[] | (keyof Video)[],
): el is (keyof Video)[] => Array.isArray(el);
