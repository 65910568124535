import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    // cursor: 'pointer', - Removed until user profile is implemented.
    width: 64,
    height: 64,
  },
  perfectScrollbar: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
