import { merge as _merge } from 'lodash';

import type { AppThunk } from 'src/redux/store';
import { Settings } from 'src/types/global';

import slice from './slice';
import { storeSettings } from './utils';

export const saveSettings = (update: Settings = {}): AppThunk => {
  return async (dispatch, getState) => {
    const currentSettings = getState().settings;
    const mergedSettings = _merge({}, currentSettings, update);

    dispatch(slice.actions.saveSettings(mergedSettings));

    storeSettings(mergedSettings);

    if (mergedSettings.direction) document.dir = mergedSettings.direction;
  };
};
