import { makeStyles } from '@material-ui/core';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  middle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '-22px', // half the height of the spinner
    marginLeft: '-20px', // half the width of the spinner
    zIndex: 9999,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(6),
    paddingTop: theme.spacing(3),
  },
  withSidebar: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '100px'
    }
  }
}));
