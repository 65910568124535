import { useEffect, useMemo, useState } from 'react';

import { OperationVariables, QueryTuple, useLazyQuery } from '@apollo/client';
import camelcaseKeys from 'camelcase-keys';
import { isEqual as _isEqual } from 'lodash';

import { useGraphqlToastHandling, usePrevious } from 'src/hooks';

import { UseLazyQueryInput } from './types';

const useLazyQueryWithErrorHandling = <T extends { [key: string]: unknown }>({
  query,
  queryName,
  options = {},
}: UseLazyQueryInput) => {
  const { onCompleted, onError } = useGraphqlToastHandling({ options });
  const [queryFunc, queryData] = useLazyQuery<T>(query, {
    fetchPolicy: 'cache-and-network',
    ...options,
    onCompleted: (res) => {
      const { success, message, data } =
        (res[queryName] as Record<string, unknown>) || {};
      onCompleted({
        success: typeof success === 'boolean' ? success : true,
        message: message as string,
        data,
      });
    },
    onError,
  });

  const { data, ...restQueryData } = queryData || {};

  const previousData = usePrevious(data);
  const [camelCasedData, setCamelCasedData] = useState<T | null>(null);

  const isDataEqual = useMemo(
    () => _isEqual(previousData, data),
    [data, previousData],
  );

  useEffect(() => {
    if (!isDataEqual && data)
      setCamelCasedData(camelcaseKeys(data, { deep: true }));
  }, [data, isDataEqual]);

  const formattedQueryData = useMemo(
    () => ({ data: camelCasedData, ...restQueryData }),
    [camelCasedData, restQueryData],
  );
  const result = useMemo(
    () => [queryFunc, formattedQueryData] as QueryTuple<T, OperationVariables>,
    [formattedQueryData, queryFunc],
  );

  return result;
};

export default useLazyQueryWithErrorHandling;
