import { useEffect, useState } from 'react';

import { Step } from 'src/types/camelCaseApi';

import { UseOrderedStepsProps } from './types';

const useOrderedSteps = ({
  orderedSections,
  groupedSteps,
}: UseOrderedStepsProps) => {
  const [orderedSteps, setOrderedSteps] = useState<Array<Step>>([]);

  useEffect(() => {
    if (!orderedSections) return;

    const newOrderedSteps: Array<Step> = [];
    orderedSections.forEach(({ sectionRecipeId }) => {
      sectionRecipeId &&
        newOrderedSteps.push(...(groupedSteps?.[sectionRecipeId] || []));
    });
    setOrderedSteps(newOrderedSteps);
  }, [groupedSteps, orderedSections]);

  return orderedSteps;
};

export default useOrderedSteps;
