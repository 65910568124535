import { useState } from 'react';

import { FocusStateProps } from './types';

const useFocus = (props?: FocusStateProps) => {
  const { initialValue = false } = props || {};
  const [isFocused, setIsFocused] = useState(initialValue);

  const onFocus = () => setIsFocused(true);

  const onBlur = () => setIsFocused(false);

  const onToggle = () => setIsFocused(!isFocused);

  return { onFocus, onBlur, isFocused, onToggle };
};

export default useFocus;
