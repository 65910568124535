import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { TOAST_VARIANT } from 'src/constants';
import { getConcatString } from 'src/utils';

import { GraphqlToastHandlingInput, OnCompletedInputType } from './types';

const useGraphqlToastHandling = ({ options }: GraphqlToastHandlingInput) => {
  const { enqueueSnackbar } = useSnackbar();
  
  const onCompleted = useCallback(
    ({ success, message, data }: OnCompletedInputType) => {
      typeof options?.onCompleted === 'function' && options.onCompleted(data);
      !success && message && enqueueSnackbar(message, TOAST_VARIANT.ERROR);
    },
    [enqueueSnackbar, options],
  );

  const onError = useCallback(
    ({ message, graphQLErrors, networkError, extraInfo }: ApolloError) => {
      typeof options?.onError === 'function' &&
        options.onError({ message, graphQLErrors, networkError, extraInfo });
      const errMessage = getConcatString([message, networkError, extraInfo]);
      enqueueSnackbar(errMessage, TOAST_VARIANT.ERROR);
    },
    [enqueueSnackbar, options],
  );

  return {
    onCompleted,
    onError,
  };
};

export default useGraphqlToastHandling;
