export const ROUTE_404 = '/404';
export const LOGIN_ROUTE = '/login';

export const RECIPE_VIEW_ROUTE = '/content/recipes';
export const RECIPE_EDIT_ROUTE = '/:env/content/recipes/:recipeId/edit';

export const INGREDIENT_VIEW_ROUTE = '/content/ingredients';
export const INGREDIENT_EDIT_ROUTE =
  '/:env/content/ingredients/:ingredientId/edit';

export const VIDEO_VIEW_ROUTE = '/content/videos';
export const VIDEO_EDIT_ROUTE = '/:env/content/videos/:videoId/edit';

export const FLAGGED_CONTENT_LIST_VIEW_ROUTE = '/moderation/flagged_content';
export const FLAGGED_CONTENT_TICKET_ROUTE = '/moderation/ticket';
export const FLAGGED_CONTENT_TICKET_VIEW_ROUTE = '/moderation/ticket/:ticketId';

export const FLAGGED_CONTENT_PROFILE_ROUTE = '/moderation/profile';
export const FLAGGED_CONTENT_PROFILE_VIEW_ROUTE =
  '/moderation/profile/:profileId';

export const CUSTOMER_SUPPORT_ROUTE = '/customer-support';

export const USERS_ROUTE = '/customer-support/users';
export const USERS_COOKING_HISTORY_ROUTE =
  '/customer-support/users/:userId/cooking-history';
export const USERS_SESSION_GRAPH_ROUTE =
  '/customer-support/users/:userId/cooking-history/:cookingHistoryId/session-graph';
export const USERS_PROFLE_ROUTE = '/customer-support/users/:userId/profile';

export const DEVICES_ROUTE = '/customer-support/devices';

export const FAQ_VIEW_ROUTE = '/help/faqs';
export const FAQ_EDIT_ROUTE = '/help/faqs/:faqId/edit';
