import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Auth from '@aws-amplify/auth';

import { apolloConfig } from 'src/config/aws-exports';

const uri = apolloConfig.betaGraphqlEndpoint;

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext(async (_, { headers }) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
      'x-api-key': process.env.REACT_APP_BETA_X_API_KEY,
    },
  };
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;
