import { makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));