import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { THEMES } from 'src/theme';
import { Settings } from 'src/types/global';

import { restoreSettings } from './utils';

const initialStateValue: Settings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.DARK,
};

const initialState = restoreSettings() || initialStateValue;

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    saveSettings(state: Settings, action: PayloadAction<Settings>) {
      state.direction = action.payload.direction;
      state.responsiveFontSizes = action.payload.responsiveFontSizes;
      state.theme = action.payload.theme;
    },
  },
});

export const { reducer } = slice;

export default slice;
