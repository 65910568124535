import { useCallback, useEffect } from 'react';

import Auth from '@aws-amplify/auth';
import Amplify, { Hub } from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';

import awsConfig, { storageConfig } from 'src/config/aws-exports';
import { useAuth } from 'src/hooks';

import { AmplifyOutput } from './types';

// set initial config for amplify
Amplify.configure(awsConfig);

Storage.configure(storageConfig);

const useAmplify = (): AmplifyOutput => {
  const { setAuth } = useAuth();

  useEffect(() => {
    // Debug
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('Amplify Sign In: ', data);
          break;
        case 'signOut':
          console.log('Amplify Sign Out: ', data);
          break;
        case 'customOAuthState':
          console.log('Amplify OAuth: ', data);
          break;
        default:
          console.log('Amplify Other Events: ', data);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const {
          attributes: {
            email,
            family_name: familyName,
            given_name: givenName,
            phone_number: phoneNumber,
            'custom:ciq_userid': id,
          },
          signInUserSession: {
            idToken: {
              jwtToken: cognitoToken,
              payload: { 'cognito:groups': cognitoGroups },
            },
          },
        } = user;
        console.log('current authenticated user: ', user);

        setAuth({
          isAuthenticated: true,
          user: {
            email,
            familyName,
            givenName,
            phoneNumber,
            cognitoGroups,
            cognitoToken,
            id,
          },
        });
      })
      .catch((error) => {
        console.log('Get Current Authenticated User Failed:', error);
        setAuth({
          isAuthenticated: false,
          user: null,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(() => {
    awsConfig.Auth.customProvider &&
      Auth.federatedSignIn({ customProvider: awsConfig.Auth.customProvider });
  }, []);

  const signOut = useCallback(() => {
    Auth.signOut();
  }, []);

  return {
    awsConfig,
    signIn,
    signOut,
  };
};

export default useAmplify;
