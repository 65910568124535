/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addActiveRecipe = /* GraphQL */ `
  mutation AddActiveRecipe($input: AddActiveRecipeInput!) {
    addActiveRecipe(input: $input) {
      code
      data {
        active_recipe_id
        created_at
        created_by
        env
        recipe_id
        updated_at
        updated_by
        versioned_recipe_url
      }
      message
      statusCode
      success
    }
  }
`;
export const addCategoryRecipe = /* GraphQL */ `
  mutation AddCategoryRecipe($input: AddCategoryRecipeInput!) {
    addCategoryRecipe(input: $input) {
      code
      data {
        category_id
        category_recipe_id
        created_at
        created_by
        order
        recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const addHowtosRecipe = /* GraphQL */ `
  mutation AddHowtosRecipe($input: AddHowtosRecipeInput!) {
    addHowtosRecipe(input: $input) {
      code
      data {
        created_at
        created_by
        video_id
        howto_recipe_id
        name
        order
        recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const addIngredientRecipe = /* GraphQL */ `
  mutation AddIngredientRecipe($input: AddIngredientRecipeInput!) {
    addIngredientRecipe(input: $input) {
      code
      data {
        amount
        created_at
        created_by
        fraction
        ingredient {
          category
          created_at
          created_by
          ingredient_id
          name
          name_plural
          parent_id
          photo
          prep
          status
          type
          unit_id
          updated_at
          updated_by
          video
          weight
          ingredient_doneness {
            image
            ingredient_doneness_id
            ingredient_id
            order
            temperature_guide_id
            temperature_guide {
              doneness
              doneness_order
              icon
              is_usda_approved
              name
              order
              remove_temp
              short_doneness
              short_name
              target_temp
              temperature_guide_id
            }
          }
        }
        ingredient_id
        ingredient_recipe_id
        ingredient_section_recipe_id
        is_main
        name
        name_plural
        optional_info
        order
        photo
        recipe_id
        step_id
        unit {
          abbreviation
          created_at
          created_by
          name
          unit_id
          updated_at
          updated_by
        }
        unit_id
        updated_at
        updated_by
        video
        weight
      }
      message
      statusCode
      success
    }
  }
`;
export const addIngredientSectionRecipe = /* GraphQL */ `
  mutation AddIngredientSectionRecipe(
    $input: AddIngredientSectionRecipeInput!
  ) {
    addIngredientSectionRecipe(input: $input) {
      code
      data {
        created_at
        created_by
        ingredient_section {
          created_at
          created_by
          ingredient_section_id
          name
          updated_at
          updated_by
        }
        ingredient_section_id
        ingredient_section_recipe_id
        name
        order
        recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const addLinkedRecipes = /* GraphQL */ `
  mutation AddLinkedRecipes($input: AddLinkedRecipesInput!) {
    addLinkedRecipes(input: $input) {
      code
      data {
        created_at
        created_by
        linked_recipe {
          active_time
          cover_photo
          cover_video
          created_at
          created_by
          description
          device_used
          difficulty_level
          has_precooked_ingredients
          howtos {
            created_at
            created_by
            video_id
            howto_recipe_id
            name
            order
            recipe_id
            updated_at
            updated_by
          }
          ingredient_sections {
            created_at
            created_by
            ingredient_section_id
            ingredient_section_recipe_id
            name
            order
            recipe_id
            updated_at
            updated_by
          }
          ingredients {
            amount
            created_at
            created_by
            fraction
            ingredient_id
            ingredient_recipe_id
            ingredient_section_recipe_id
            is_main
            name
            name_plural
            optional_info
            order
            photo
            recipe_id
            step_id
            unit_id
            updated_at
            updated_by
            video
            weight
          }
          linked_recipes {
            created_at
            created_by
            linked_recipe_id
            source_linked_recipe_id
            source_recipe_id
            updated_at
            updated_by
          }
          name
          recipe_developer_id
          recipe_id
          recipe_number
          sections {
            created_at
            created_by
            name
            order
            recipe_id
            section_id
            section_recipe_id
            updated_at
            updated_by
          }
          status
          steps {
            actions
            button_text
            conditions
            created_at
            created_by
            description
            has_timer
            photo
            recipe_id
            section_recipe_id
            smart
            step_id
            step_number
            stop_actions
            updated_at
            updated_by
            video
          }
          tags {
            created_at
            created_by
            name
            order
            recipe_id
            tag_id
            tag_recipe_id
            type
            updated_at
            updated_by
          }
          tools {
            created_at
            created_by
            name
            order
            recipe_id
            tool_id
            tool_recipe_id
            updated_at
            updated_by
          }
          total_likes
          total_time
          updated_at
          updated_by
          yield_number
          yield_type
        }
        linked_recipe_id
        source_linked_recipe_id
        source_recipe {
          active_time
          cover_photo
          cover_video
          created_at
          created_by
          description
          device_used
          difficulty_level
          has_precooked_ingredients
          howtos {
            created_at
            created_by
            video_id
            howto_recipe_id
            name
            order
            recipe_id
            updated_at
            updated_by
          }
          ingredient_sections {
            created_at
            created_by
            ingredient_section_id
            ingredient_section_recipe_id
            name
            order
            recipe_id
            updated_at
            updated_by
          }
          ingredients {
            amount
            created_at
            created_by
            fraction
            ingredient_id
            ingredient_recipe_id
            ingredient_section_recipe_id
            is_main
            name
            name_plural
            optional_info
            order
            photo
            recipe_id
            step_id
            unit_id
            updated_at
            updated_by
            video
            weight
          }
          linked_recipes {
            created_at
            created_by
            linked_recipe_id
            source_linked_recipe_id
            source_recipe_id
            updated_at
            updated_by
          }
          name
          recipe_developer_id
          recipe_id
          recipe_number
          sections {
            created_at
            created_by
            name
            order
            recipe_id
            section_id
            section_recipe_id
            updated_at
            updated_by
          }
          status
          steps {
            actions
            button_text
            conditions
            created_at
            created_by
            description
            has_timer
            photo
            recipe_id
            section_recipe_id
            smart
            step_id
            step_number
            stop_actions
            updated_at
            updated_by
            video
          }
          tags {
            created_at
            created_by
            name
            order
            recipe_id
            tag_id
            tag_recipe_id
            type
            updated_at
            updated_by
          }
          tools {
            created_at
            created_by
            name
            order
            recipe_id
            tool_id
            tool_recipe_id
            updated_at
            updated_by
          }
          total_likes
          total_time
          updated_at
          updated_by
          yield_number
          yield_type
        }
        source_recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const addSectionRecipe = /* GraphQL */ `
  mutation AddSectionRecipe($input: AddSectionRecipeInput!) {
    addSectionRecipe(input: $input) {
      code
      data {
        created_at
        created_by
        name
        order
        recipe_id
        model_number
        duration
        section {
          created_at
          created_by
          name
          section_id
          updated_at
          updated_by
        }
        section_id
        section_recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const addStepRecipe = /* GraphQL */ `
  mutation AddStepRecipe($input: AddStepRecipeInput!) {
    addStepRecipe(input: $input) {
      code
      data {
        actions
        button_text
        conditions
        created_at
        created_by
        description
        has_timer
        photo
        recipe_id
        section_recipe_id
        smart
        step_id
        step_number
        stop_actions
        updated_at
        updated_by
        video
      }
      message
      statusCode
      success
    }
  }
`;
export const addTagsRecipe = /* GraphQL */ `
  mutation AddTagsRecipe($input: AddTagsRecipeInput!) {
    addTagsRecipe(input: $input) {
      code
      data {
        created_at
        created_by
        name
        order
        recipe_id
        tag {
          created_at
          created_by
          name
          show_filter
          tag_id
          type
          updated_at
          updated_by
        }
        tag_id
        tag_recipe_id
        type
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const addToolsRecipe = /* GraphQL */ `
  mutation AddToolsRecipe($input: AddToolsRecipeInput!) {
    addToolsRecipe(input: $input) {
      code
      data {
        created_at
        created_by
        name
        order
        recipe_id
        tool {
          created_at
          created_by
          name
          photo
          tool_id
          updated_at
          updated_by
        }
        tool_id
        tool_recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const addVideo = /* GraphQL */ `
  mutation AddVideo($input: AddVideoInput) {
    addVideo(input: $input) {
      code
      data {
        video_category_id
        created_at
        created_by
        name
        photo
        status
        updated_at
        updated_by
        video
        video_id
      }
      message
      statusCode
      success
    }
  }
`;
export const addYieldType = /* GraphQL */ `
  mutation AddYieldType($input: AddYieldTypeInput!) {
    addYieldType(input: $input) {
      code
      data {
        created_at
        created_by
        name
        updated_at
        updated_by
        yield_id
      }
      message
      statusCode
      success
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      code
      data {
        category_id
        created_at
        created_by
        name
        order
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const createFraction = /* GraphQL */ `
  mutation CreateFraction($input: CreateFractionInput!) {
    createFraction(input: $input) {
      code
      data {
        created_at
        created_by
        fraction_id
        updated_at
        updated_by
        value
      }
      message
      statusCode
      success
    }
  }
`;
export const createHowto = /* GraphQL */ `
  mutation CreateHowto($input: CreateHowtoInput!) {
    createHowto(input: $input) {
      code
      data {
        created_at
        created_by
        video_id
        name
        order
        photo
        updated_at
        updated_by
        video
      }
      message
      statusCode
      success
    }
  }
`;
export const createIngredient = /* GraphQL */ `
  mutation CreateIngredient($input: CreateIngredientInput!) {
    createIngredient(input: $input) {
      code
      data {
        category
        created_at
        created_by
        ingredient_id
        name
        name_plural
        parent_id
        photo
        prep
        status
        type
        unit_id
        updated_at
        updated_by
        video
        weight
      }
      message
      statusCode
      success
    }
  }
`;
export const createIngredientCategory = /* GraphQL */ `
  mutation CreateIngredientCategory($input: CreateIngredientCategoryInput!) {
    createIngredientCategory(input: $input) {
      code
      data {
        created_at
        created_by
        ingredient_category_id
        name
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const createIngredientSection = /* GraphQL */ `
  mutation CreateIngredientSection($input: CreateIngredientSectionInput!) {
    createIngredientSection(input: $input) {
      code
      data {
        created_at
        created_by
        ingredient_section_id
        name
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const createRecipe = /* GraphQL */ `
  mutation CreateRecipe($input: CreateRecipeInput!) {
    createRecipe(input: $input) {
      code
      data {
        active_time
        cover_photo
        cover_video
        created_at
        created_by
        description
        device_used
        difficulty_level
        has_precooked_ingredients
        howtos {
          created_at
          created_by
          video_id
          howto_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredient_sections {
          created_at
          created_by
          ingredient_section {
            created_at
            created_by
            ingredient_section_id
            name
            updated_at
            updated_by
          }
          ingredient_section_id
          ingredient_section_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredients {
          amount
          created_at
          created_by
          fraction
          ingredient {
            category
            created_at
            created_by
            ingredient_id
            name
            name_plural
            parent_id
            photo
            prep
            status
            type
            unit_id
            updated_at
            updated_by
            video
            weight
          }
          ingredient_id
          ingredient_recipe_id
          ingredient_section_recipe_id
          is_main
          name
          name_plural
          optional_info
          order
          photo
          recipe_id
          step_id
          unit {
            abbreviation
            created_at
            created_by
            name
            unit_id
            updated_at
            updated_by
          }
          unit_id
          updated_at
          updated_by
          video
          weight
        }
        linked_recipes {
          created_at
          created_by
          linked_recipe {
            active_time
            cover_photo
            cover_video
            created_at
            created_by
            description
            device_used
            difficulty_level
            has_precooked_ingredients
            name
            recipe_developer_id
            recipe_id
            recipe_number
            status
            total_likes
            total_time
            updated_at
            updated_by
            yield_number
            yield_type
          }
          linked_recipe_id
          source_linked_recipe_id
          source_recipe {
            active_time
            cover_photo
            cover_video
            created_at
            created_by
            description
            device_used
            difficulty_level
            has_precooked_ingredients
            name
            recipe_developer_id
            recipe_id
            recipe_number
            status
            total_likes
            total_time
            updated_at
            updated_by
            yield_number
            yield_type
          }
          source_recipe_id
          updated_at
          updated_by
        }
        name
        recipe_developer_id
        recipe_id
        recipe_number
        sections {
          created_at
          created_by
          name
          order
          recipe_id
          section {
            created_at
            created_by
            name
            section_id
            updated_at
            updated_by
          }
          section_id
          section_recipe_id
          updated_at
          updated_by
        }
        status
        steps {
          actions
          button_text
          conditions
          created_at
          created_by
          description
          has_timer
          photo
          recipe_id
          section_recipe_id
          smart
          step_id
          step_number
          stop_actions
          updated_at
          updated_by
          video
        }
        tags {
          created_at
          created_by
          name
          order
          recipe_id
          tag {
            created_at
            created_by
            name
            show_filter
            tag_id
            type
            updated_at
            updated_by
          }
          tag_id
          tag_recipe_id
          type
          updated_at
          updated_by
        }
        tools {
          created_at
          created_by
          name
          order
          recipe_id
          tool {
            created_at
            created_by
            name
            photo
            tool_id
            updated_at
            updated_by
          }
          tool_id
          tool_recipe_id
          updated_at
          updated_by
        }
        total_likes
        total_time
        updated_at
        updated_by
        yield_number
        yield_type
      }
      message
      statusCode
      success
    }
  }
`;

export const createIngredientDoneness = /* GraphQL */ `
  mutation CreateIngredientDoneness(
    $image: AWSURL
    $ingredient_id: String!
    $order: Int
    $temperature_guide_id: String!
  ) {
    ingredientDonenessAdd(
      image: $image
      ingredient_id: $ingredient_id
      order: $order
      temperature_guide_id: $temperature_guide_id
    ) {
      created_at
      created_by
      image
      ingredient_doneness_id
      ingredient_id
      order
      temperature_guide_id
      updated_at
      updated_by
    }
  }
`;

export const removeIngredientDoneness = /* GraphQL */ `
  mutation RemoveIngredientDoneness($ingredient_doneness_id: String!) {
    ingredientDonenessRemove(ingredient_doneness_id: $ingredient_doneness_id) {
      success
    }
  }
`;

export const updateIngredientDoneness = /* GraphQL */ `
  mutation UpdateIngredientDoneness(
    $image: AWSURL
    $ingredient_doneness_id: String!
    $order: Int
  ) {
    ingredientDonenessUpdate(
      image: $image
      ingredient_doneness_id: $ingredient_doneness_id
      order: $order
    ) {
      created_at
      created_by
      image
      ingredient_doneness_id
      ingredient_id
      order
      temperature_guide_id
      updated_at
      updated_by
    }
  }
`;

export const createSection = /* GraphQL */ `
  mutation CreateSection($input: CreateSectionInput!) {
    createSection(input: $input) {
      code
      data {
        created_at
        created_by
        name
        section_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      code
      data {
        created_at
        created_by
        name
        show_filter
        tag_id
        type
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const createTool = /* GraphQL */ `
  mutation CreateTool($input: CreateToolInput!) {
    createTool(input: $input) {
      code
      data {
        created_at
        created_by
        name
        photo
        tool_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit($input: CreateUnitInput!) {
    createUnit(input: $input) {
      code
      data {
        abbreviation
        created_at
        created_by
        name
        unit_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const deleteIngredientSection = /* GraphQL */ `
  mutation DeleteIngredientSection($input: DeleteIngredientSectionInput!) {
    deleteIngredientSection(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const deleteRecipe = /* GraphQL */ `
  mutation DeleteRecipe($input: DeleteRecipeInput!) {
    deleteRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection($input: DeleteSectionInput!) {
    deleteSection(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const deleteYieldType = /* GraphQL */ `
  mutation DeleteYieldType($input: DeleteYieldTypeInput!) {
    deleteYieldType(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeCategoryRecipe = /* GraphQL */ `
  mutation RemoveCategoryRecipe($input: RemoveCategoryRecipeInput!) {
    removeCategoryRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeFraction = /* GraphQL */ `
  mutation RemoveFraction($input: RemoveFractionInput!) {
    removeFraction(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeHowtosRecipe = /* GraphQL */ `
  mutation RemoveHowtosRecipe($input: RemoveHowtosRecipeInput!) {
    removeHowtosRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeIngredient = /* GraphQL */ `
  mutation RemoveIngredient($input: RemoveIngredientInput!) {
    removeIngredient(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeIngredientCategory = /* GraphQL */ `
  mutation RemoveIngredientCategory($input: RemoveIngredientCategoryInput!) {
    removeIngredientCategory(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeIngredientRecipe = /* GraphQL */ `
  mutation RemoveIngredientRecipe($input: RemoveIngredientRecipeInput!) {
    removeIngredientRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeIngredientSectionRecipe = /* GraphQL */ `
  mutation RemoveIngredientSectionRecipe(
    $input: RemoveIngredientSectionRecipeInput!
  ) {
    removeIngredientSectionRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeLinkedRecipes = /* GraphQL */ `
  mutation RemoveLinkedRecipes($input: RemoveLinkedRecipesInput!) {
    removeLinkedRecipes(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeSectionRecipe = /* GraphQL */ `
  mutation RemoveSectionRecipe($input: RemoveSectionRecipeInput!) {
    removeSectionRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeStepRecipe = /* GraphQL */ `
  mutation RemoveStepRecipe($input: RemoveStepRecipeInput!) {
    removeStepRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeTagsRecipe = /* GraphQL */ `
  mutation RemoveTagsRecipe($input: RemoveTagsRecipeInput!) {
    removeTagsRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeToolsRecipe = /* GraphQL */ `
  mutation RemoveToolsRecipe($input: RemoveToolsRecipeInput!) {
    removeToolsRecipe(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeUnit = /* GraphQL */ `
  mutation RemoveUnit($input: RemoveUnitInput!) {
    removeUnit(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const removeVideo = /* GraphQL */ `
  mutation RemoveVideo($input: RemoveVideoInput) {
    removeVideo(input: $input) {
      code
      message
      statusCode
      success
    }
  }
`;
export const replaceIngredient = /* GraphQL */ `
  mutation ReplaceIngredient($input: UpdateIngredientInput!) {
    replaceIngredient(input: $input) {
      code
      data {
        category
        created_at
        created_by
        ingredient_id
        name
        name_plural
        parent_id
        photo
        prep
        pre
        post
        status
        type
        unit_id
        updated_at
        updated_by
        video
        weight
      }
      message
      statusCode
      success
    }
  }
`;
export const replaceStepRecipe = /* GraphQL */ `
  mutation ReplaceStepRecipe($input: UpdateStepRecipeInput!) {
    replaceStepRecipe(input: $input) {
      code
      data {
        actions
        button_text
        conditions
        created_at
        created_by
        description
        has_timer
        photo
        recipe_id
        section_recipe_id
        smart
        step_id
        step_number
        stop_actions
        updated_at
        updated_by
        video
      }
      message
      statusCode
      success
    }
  }
`;
export const updateFraction = /* GraphQL */ `
  mutation UpdateFraction($input: UpdateFractionInput!) {
    updateFraction(input: $input) {
      code
      data {
        created_at
        created_by
        fraction_id
        updated_at
        updated_by
        value
      }
      message
      statusCode
      success
    }
  }
`;
export const updateIngredient = /* GraphQL */ `
  mutation UpdateIngredient($input: UpdateIngredientInput!) {
    updateIngredient(input: $input) {
      code
      data {
        category
        created_at
        created_by
        ingredient_id
        name
        name_plural
        parent_id
        photo
        prep
        pre
        post
        status
        type
        unit_id
        updated_at
        updated_by
        video
        weight
      }
      message
      statusCode
      success
    }
  }
`;
export const updateIngredientCategory = /* GraphQL */ `
  mutation UpdateIngredientCategory($input: UpdateIngredientCategoryInput!) {
    updateIngredientCategory(input: $input) {
      code
      data {
        created_at
        created_by
        ingredient_category_id
        name
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const updateIngredientRecipe = /* GraphQL */ `
  mutation UpdateIngredientRecipe($input: UpdateIngredientRecipeInput!) {
    updateIngredientRecipe(input: $input) {
      code
      data {
        amount
        created_at
        created_by
        fraction
        ingredient {
          category
          created_at
          created_by
          ingredient_id
          name
          name_plural
          parent_id
          photo
          prep
          status
          type
          unit_id
          updated_at
          updated_by
          video
          weight
        }
        ingredient_id
        ingredient_recipe_id
        ingredient_section_recipe_id
        is_main
        name
        name_plural
        optional_info
        order
        photo
        recipe_id
        step_id
        unit {
          abbreviation
          created_at
          created_by
          name
          unit_id
          updated_at
          updated_by
        }
        unit_id
        updated_at
        updated_by
        video
        weight
      }
      message
      statusCode
      success
    }
  }
`;
export const updateIngredientSection = /* GraphQL */ `
  mutation UpdateIngredientSection($input: UpdateIngredientSectionInput!) {
    updateIngredientSection(input: $input) {
      code
      data {
        created_at
        created_by
        ingredient_section_id
        name
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const updateIngredientSectionRecipe = /* GraphQL */ `
  mutation UpdateIngredientSectionRecipe(
    $input: UpdateIngredientSectionRecipeInput!
  ) {
    updateIngredientSectionRecipe(input: $input) {
      code
      data {
        created_at
        created_by
        ingredient_section {
          created_at
          created_by
          ingredient_section_id
          name
          updated_at
          updated_by
        }
        ingredient_section_id
        ingredient_section_recipe_id
        name
        order
        recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const updateRecipe = /* GraphQL */ `
  mutation UpdateRecipe($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
      code
      data {
        active_time
        cover_photo
        cover_video
        cover_trailer
        created_at
        created_by
        description
        device_used
        appliance_id
        difficulty_level
        has_precooked_ingredients
        howtos {
          created_at
          created_by
          video_id
          howto_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredient_sections {
          created_at
          created_by
          ingredient_section {
            created_at
            created_by
            ingredient_section_id
            name
            updated_at
            updated_by
          }
          ingredient_section_id
          ingredient_section_recipe_id
          name
          order
          recipe_id
          updated_at
          updated_by
        }
        ingredients {
          amount
          created_at
          created_by
          fraction
          ingredient {
            category
            created_at
            created_by
            ingredient_id
            name
            name_plural
            parent_id
            photo
            prep
            status
            type
            unit_id
            updated_at
            updated_by
            video
            weight
          }
          ingredient_id
          ingredient_recipe_id
          ingredient_section_recipe_id
          is_main
          name
          name_plural
          optional_info
          order
          photo
          recipe_id
          step_id
          unit {
            abbreviation
            created_at
            created_by
            name
            unit_id
            updated_at
            updated_by
          }
          unit_id
          updated_at
          updated_by
          video
          weight
        }
        linked_recipes {
          created_at
          created_by
          linked_recipe {
            active_time
            cover_photo
            cover_video
            created_at
            created_by
            description
            device_used
            difficulty_level
            has_precooked_ingredients
            name
            recipe_developer_id
            recipe_id
            recipe_number
            status
            total_likes
            total_time
            updated_at
            updated_by
            yield_number
            yield_type
          }
          linked_recipe_id
          source_linked_recipe_id
          source_recipe {
            active_time
            cover_photo
            cover_video
            created_at
            created_by
            description
            device_used
            difficulty_level
            has_precooked_ingredients
            name
            recipe_developer_id
            recipe_id
            recipe_number
            status
            total_likes
            total_time
            updated_at
            updated_by
            yield_number
            yield_type
          }
          source_recipe_id
          updated_at
          updated_by
        }
        name
        recipe_developer_id
        recipe_id
        recipe_number
        sections {
          created_at
          created_by
          name
          order
          recipe_id
          model_number
          section {
            created_at
            created_by
            name
            section_id
            updated_at
            updated_by
          }
          section_id
          section_recipe_id
          updated_at
          updated_by
        }
        status
        steps {
          actions
          button_text
          conditions
          created_at
          created_by
          description
          has_timer
          photo
          recipe_id
          section_recipe_id
          smart
          step_id
          step_number
          stop_actions
          updated_at
          updated_by
          video
        }
        tags {
          created_at
          created_by
          name
          order
          recipe_id
          tag {
            created_at
            created_by
            name
            show_filter
            tag_id
            type
            updated_at
            updated_by
          }
          tag_id
          tag_recipe_id
          type
          updated_at
          updated_by
        }
        tools {
          created_at
          created_by
          name
          order
          recipe_id
          tool {
            created_at
            created_by
            name
            photo
            tool_id
            updated_at
            updated_by
          }
          tool_id
          tool_recipe_id
          updated_at
          updated_by
        }
        total_likes
        total_time
        updated_at
        updated_by
        yield_number
        yield_type
      }
      message
      statusCode
      success
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection($input: UpdateSectionInput!) {
    updateSection(input: $input) {
      code
      data {
        created_at
        created_by
        name
        section_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const updateSectionRecipe = /* GraphQL */ `
  mutation UpdateSectionRecipe($input: UpdateSectionRecipeInput!) {
    updateSectionRecipe(input: $input) {
      code
      data {
        created_at
        created_by
        name
        order
        recipe_id
        model_number
        duration
        section {
          created_at
          created_by
          name
          section_id
          updated_at
          updated_by
        }
        section_id
        section_recipe_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const updateStepRecipe = /* GraphQL */ `
  mutation UpdateStepRecipe($input: UpdateStepRecipeInput!) {
    updateStepRecipe(input: $input) {
      code
      data {
        actions
        button_text
        conditions
        created_at
        created_by
        description
        has_timer
        photo
        recipe_id
        section_recipe_id
        smart
        step_id
        step_number
        stop_actions
        updated_at
        updated_by
        video
      }
      message
      statusCode
      success
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit($input: UpdateUnitInput!) {
    updateUnit(input: $input) {
      code
      data {
        abbreviation
        created_at
        created_by
        name
        unit_id
        updated_at
        updated_by
      }
      message
      statusCode
      success
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo($input: UpdateVideoInput) {
    updateVideo(input: $input) {
      code
      data {
        video_category_id
        created_at
        created_by
        name
        photo
        status
        updated_at
        updated_by
        video
        video_id
      }
      message
      statusCode
      success
    }
  }
`;
export const updateYieldType = /* GraphQL */ `
  mutation UpdateYieldType($input: UpdateYieldTypeInput!) {
    updateYieldType(input: $input) {
      code
      data {
        created_at
        created_by
        name
        updated_at
        updated_by
        yield_id
      }
      message
      statusCode
      success
    }
  }
`;
export const versionRecipe = /* GraphQL */ `
  mutation VersionRecipe($input: VersionRecipeInput!) {
    versionRecipe(input: $input) {
      code
      data {
        ETag
        VersionId
        url
      }
      message
      statusCode
      success
    }
  }
`;

export const createReport = /* GraphQL */ `
  mutation CreateReport($report: ReportInput!) {
    reportCreate(report: $report) {
      report_id
      content_id
      content_type
      report_type_id
      message
    }
  }
`;

export const addTicketAction = /* GraphQL */ `
  mutation AddTicketAction($action: TicketActionInput!) {
    ticketActionAdd(action: $action) {
      ticket_action_id
      ticket_id
    }
  }
`;

export const addModelRecipe = /* GraphQL */ `
  mutation AddModelRecipe(
    $recipe_id: ID!
    $models_recipes: [ModelRecipeInput]!
  ) {
    modelRecipeAdd(recipe_id: $recipe_id, models_recipes: $models_recipes) {
      model_number
      model_recipe_id
    }
  }
`;
export const removeModelRecipe = /* GraphQL */ `
  mutation RemoveModelRecipe($recipe_id: ID!) {
    modelRecipeRemove(recipe_id: $recipe_id) {
      success
    }
  }
`;
export const addModelSectionRecipe = /* GraphQL */ `
  mutation AddModelSectionRecipe(
    $section_recipe_id: ID!
    $models_sections_recipes: [ModelSectionRecipeInput]!
  ) {
    modelSectionRecipeAdd(
      section_recipe_id: $section_recipe_id
      models_sections_recipes: $models_sections_recipes
    ) {
      model_number
      model_section_recipe_id
      section_recipe_id
    }
  }
`;
export const removeModelSectionRecipe = /* GraphQL */ `
  mutation RemoveModelSectionRecipe($section_recipe_id: ID!) {
    modelSectionRecipeRemove(section_recipe_id: $section_recipe_id) {
      success
    }
  }
`;

export const addProfilesRecipe = /* GraphQL */ `
  mutation AddProfileRecipe($profiles_recipes: [ProfileRecipeInput]!, $recipe_id: ID!) {
    profileRecipeAdd(profiles_recipes: $profiles_recipes, recipe_id: $recipe_id) {
      user_id
      recipe_id
      profile_recipe_id
      profile {
        username
      }
    }
  }
`;

export const removeProfileRecipe = /* GraphQL */ `
  mutation RemoveProfileRecipe($profile_recipe_id: ID!) {
    profileRecipeRemove(profile_recipe_id: $profile_recipe_id) {
      success
    }
  }
`;