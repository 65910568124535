import { Component, ErrorInfo, ReactNode } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { ErrorBoundaryProps, ErrorBoundaryState } from './types';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  handleOnClick = () => {
    // TODO: Need to think of better approach to refresh
    window.location.reload();
  };

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { children } = this.props;

    return (
      <>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={hasError}
          maxWidth="md"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          style={{ backgroundColor: '#f7f9f8', color: '#222624' }}
        >
          <DialogTitle
            id="customized-dialog-title"
            disableTypography
            style={{
              textAlign: 'center',
              backgroundColor: '#c0e9d7',
            }}
          >
            <Typography variant="h4">Application Error</Typography>
          </DialogTitle>
          <DialogContent dividers>
            {error?.stack?.toString() ?? ''}
          </DialogContent>
          <DialogActions>
            <Box mx="10px">
              <Button
                onClick={this.handleOnClick}
                variant="contained"
                style={{
                  backgroundColor: '#1a1d1b',
                  color: '#ffffff',
                }}
              >
                REFRESH PAGE
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        {!hasError && children}
      </>
    );
  }
}

export default ErrorBoundary;
