import { useMemo, useCallback } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

const useUrlSearchParams = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const result: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  }, [search]);

  const setParams = useCallback(
    (searchParams: string) => {
      history.push({ pathname, search: searchParams });
    },
    [history, pathname],
  );

  return [params, setParams] as const;
};

export default useUrlSearchParams;
