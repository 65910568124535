import { useMemo } from 'react';

import { isNil as _isNil } from 'lodash';

import { PERMISSIONS, ROLE_PERMISSIONS } from 'src/constants/global';
import { useAuth } from 'src/hooks';

import {
  PermissionsRequest,
  GrantedPermissions,
  PermissionValue,
  ContentTypeValue,
} from './types';

const usePermissions = (
  requestedPermissions: PermissionsRequest,
  contentCreatorId?: string | null,
): GrantedPermissions => {
  const { user } = useAuth();
  const { cognitoGroups = [] } = user || {};

  return useMemo(() => {
    const grantedPermissions: GrantedPermissions = {};

    Object.entries(requestedPermissions).forEach(
      ([contentType, permission]) => {
        const grantedPermissionsForContentType: Partial<
          Record<PermissionValue, boolean>
        > = {};

        permission.forEach((permissionValue) => {
          const permissions = ROLE_PERMISSIONS[contentType as ContentTypeValue];
          const permissionRoles =
            permissions[permissionValue as keyof typeof permissions] || [];

          const isContentCreator =
            !_isNil(contentCreatorId) && contentCreatorId === user?.id;

          const hasPermission =
            (isContentCreator &&
              permissionValue !== PERMISSIONS.PUSH_TO_PROD) ||
            permissionRoles.some((permissionRole) =>
              cognitoGroups.includes(permissionRole),
            );

          grantedPermissionsForContentType[permissionValue] = hasPermission;
        });

        grantedPermissions[contentType as ContentTypeValue] =
          grantedPermissionsForContentType;
      },
    );
    return grantedPermissions;
  }, [cognitoGroups, contentCreatorId, requestedPermissions, user?.id]);
};

export default usePermissions;
