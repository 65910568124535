import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { AuthState, SetAuthPayload } from 'src/types/global';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setAuth(state: AuthState, action: PayloadAction<SetAuthPayload>) {
      state.isInitialized = true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },
  },
});

export const { reducer } = slice;

export default slice;
