import type { AppThunk } from 'src/redux/store';
import { SetAuthPayload } from 'src/types/global';

import slice from './slice';

export const setAuth =
  (auth: SetAuthPayload): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setAuth(auth));
  };
