import { useEffect, useMemo, ReactElement, Fragment, useCallback } from 'react';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';

import Logo from 'src/components/Logo';
import RoleGuard from 'src/components/RoleGuard';
import { RoleGuardProps } from 'src/components/RoleGuard/types';
import { COGNITO_GROUPS_MAP, NAV_SECTIONS, NavSection } from 'src/constants';
import { useAuth } from 'src/hooks';
import { getConcatString } from 'src/utils';

import { useStyles } from './index.style';
import NavItem from './NavItem';
import { ChildRoutesProps, NavBarProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../../../package.json');

const CURRENT_YEAR = new Date().getFullYear();

function reduceChildRoutes({ acc, pathname, item, depth }: ChildRoutesProps) {
  const key = item.title + depth;

  const isActive = item.href && pathname.includes(item.href);

  const Wrapper = item.requiredPermissions ? RoleGuard : Fragment;

  const wrapperProps = item.requiredPermissions
    ? ({
        requiredPermissions: item.requiredPermissions,
        disabledBehavior: 'hide',
        requirementType: 'any',
      } as RoleGuardProps)
    : {};

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <Wrapper key={key} {...wrapperProps}>
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
          isActive={!!isActive}
        >
          <List disablePadding>
            {item.items.reduce(
              (result, el) =>
                reduceChildRoutes({
                  acc: result,
                  item: el,
                  pathname,
                  depth: depth + 1,
                }),
              [] as Array<ReactElement>,
            )}
          </List>
        </NavItem>
      </Wrapper>,
    );
  } else {
    acc.push(
      <Wrapper key={key} {...wrapperProps}>
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          isActive={!!isActive}
        />
      </Wrapper>,
    );
  }

  return acc;
}

const NavBar = ({ onMobileClose, openMobile }: NavBarProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { familyName, givenName, cognitoGroups } = user || {};

  const userName = useMemo(
    () => getConcatString([givenName, familyName]),
    [familyName, givenName],
  );

  const roles = useMemo(() => {
    const validGroupsList: Array<string> = [];

    cognitoGroups?.forEach((group) => {
      if (Object.keys(COGNITO_GROUPS_MAP).includes(group)) {
        validGroupsList.push(COGNITO_GROUPS_MAP[group]);
      }
    });

    return getConcatString(validGroupsList);
  }, [cognitoGroups]);

  const renderSection = useCallback(
    (section: NavSection) => {
      const Wrapper = section.requiredPermissions ? RoleGuard : Fragment;

      const wrapperProps = section.requiredPermissions
        ? ({
            requiredPermissions: section.requiredPermissions,
            disabledBehavior: 'hide',
            requirementType: 'any',
          } as RoleGuardProps)
        : {};

      return (
        <Wrapper key={section.subheader} {...wrapperProps}>
          <List
            key={section.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {section.subheader}
              </ListSubheader>
            }
          >
            <List disablePadding>
              {section.items.reduce(
                (acc, item) =>
                  reduceChildRoutes({
                    acc,
                    item,
                    pathname: location.pathname,
                    depth: 0,
                  }),
                [] as Array<ReactElement>,
              )}
            </List>
          </List>
        </Wrapper>
      );
    },
    [location.pathname],
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar
        className={classes.perfectScrollbar}
        options={{ suppressScrollX: true }}
      >
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            {/* - Removing this link until user profile is implemented.
            <RouterLink to={USER_PROFILE_VIEW}>
              <Avatar alt="User" className={classes.avatar} src="" />
            </RouterLink>
            */}
            <Avatar alt="User" className={classes.avatar} src="" />
          </Box>
          <Box mt={2} textAlign="center">
            {/* - Removing this link until user profile is implemented.
            <Link
              component={RouterLink}
              to={USER_PROFILE_VIEW}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {userName}
            </Link>
            */}
            <Typography variant="h5" color="textPrimary">
              {userName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {roles}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>{NAV_SECTIONS.map(renderSection)}</Box>
        <Box mt="auto">
          <Divider />
          <Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="caption" color="textSecondary">
                v{version}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="caption" color="textSecondary">
                © Copyright {CURRENT_YEAR} CHEF iQ®
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="caption" color="textSecondary">
                ALL THE RIGHTS RESERVED
              </Typography>
            </Box>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
