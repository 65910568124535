import { useEffect, useState } from 'react';

import { UseFirstNameWithInitialInput } from './types';

const useFirstNameWithInitial = ({
  userName,
}: UseFirstNameWithInitialInput) => {
  const [nameWithInitial, setNameWithInitial] = useState('');

  useEffect(() => {
    const [firstname, lastname] = (userName || '').split(' ');
    const formattedName = `${firstname || ''} ${lastname?.[0] || ''}`.trim();
    setNameWithInitial(formattedName);
  }, [userName]);

  return nameWithInitial;
};

export default useFirstNameWithInitial;
