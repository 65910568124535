import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
} from '@material-ui/core';
import clsx from 'clsx';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

import Logo from 'src/components/Logo';

import Account from './Account';
import { useStyles } from './index.style';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import { TopBarProps } from './types';

const TopBar = ({ className, onMobileNavOpen, ...rest }: TopBarProps) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton className={classes.iconButton} onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* 
        // TODO: Add once the feature is implemented
        <Search /> 
        <Notifications /> 
        */}
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
