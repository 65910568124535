import {
  ENVIRONMENT,
  INGREDIENT_EDIT_ROUTE,
  INGREDIENT_VIEW_ROUTE,
  RECIPE_EDIT_ROUTE,
  RECIPE_VIEW_ROUTE,
  VIDEO_EDIT_ROUTE,
  VIDEO_VIEW_ROUTE,
} from 'src/constants';

import {
  GetIngredientEditRoute,
  GetRecipeEditRoute,
  GetVideoEditRoute,
} from './types';

export const getRecipeEditRoute = ({
  recipeId,
  env = ENVIRONMENT.DEV,
}: GetRecipeEditRoute) => {
  if (!recipeId) return RECIPE_VIEW_ROUTE;

  return RECIPE_EDIT_ROUTE.replace(':recipeId', recipeId).replace(':env', env);
};

export const getIngredientEditRoute = ({
  ingredientId,
  env = ENVIRONMENT.DEV,
}: GetIngredientEditRoute) => {
  if (!ingredientId) return INGREDIENT_VIEW_ROUTE;

  return INGREDIENT_EDIT_ROUTE.replace(':ingredientId', ingredientId).replace(
    ':env',
    env,
  );
};

export const getVideoEditRoute = ({
  videoId,
  env = ENVIRONMENT.DEV,
}: GetVideoEditRoute) => {
  if (!videoId) return VIDEO_VIEW_ROUTE;

  return VIDEO_EDIT_ROUTE.replace(':videoId', videoId).replace(':env', env);
};
