import { makeStyles } from '@material-ui/core';

import { Theme, THEMES } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      }
      : {}),
    ...(theme.name === THEMES.DARK
      ? {
        backgroundColor: theme.palette.background.default,
      }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  iconButton: {
    color: theme.palette.text.primary,
  }
}));
