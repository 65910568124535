import { useMediaQuery } from '@material-ui/core';

import { betaClient, devClient, prodClient } from 'src/apollo';
import {
  ENVIRONMENT,
  FLAGGED_CONTENT_TAB_LABEL,
  FlaggedContentType,
} from 'src/constants';

import { InputConfigType, OptionsType, GetTicketsConfigInput } from './types';

const useGetTicketsConfig = ({
  tab,
  afterIndex,
  inputVariables,
}: GetTicketsConfigInput) => {
  const largerThanFullHD = useMediaQuery('(min-width:2000px)');
  const getInputConfig: InputConfigType = {
    ...inputVariables,
  };

  if (
    [FLAGGED_CONTENT_TAB_LABEL.OPEN, FLAGGED_CONTENT_TAB_LABEL.CLOSED].includes(
      tab,
    )
  ) {
    getInputConfig.status = tab as unknown as FlaggedContentType;
  }

  const fetchMoreInputConfig: InputConfigType = { ...getInputConfig };

  getInputConfig.limit = largerThanFullHD ? 30 : 20;
  getInputConfig.content_type = 'COMMENT';

  if (afterIndex) fetchMoreInputConfig.after = afterIndex;

  const getOptions: OptionsType = {
    variables: {
      ...getInputConfig,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
  };

  const fetchMoreOptions: OptionsType = {
    variables: {
      ...fetchMoreInputConfig,
    },
  };

  getOptions.client = prodClient;
  fetchMoreOptions.client = prodClient;

  return { getOptions, fetchMoreOptions };
};

export default useGetTicketsConfig;
