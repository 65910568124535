import {
  Video as VideoIcon,
  Smartphone as SmartphoneIcon,
  Coffee as CoffeeIcon, // TODO: temporary icon
  Feather as FeatherIcon, // TODO: temporary icon
  Flag as FlagIcon,
  BarChart2 as BarChartIcon,
  Users as UsersIcon,
  HelpCircle as HelpCircleIcon,
} from 'react-feather';

import { StatusUtilsType, ToastVariant } from 'src/types/global';

import {
  INGREDIENT_VIEW_ROUTE,
  RECIPE_VIEW_ROUTE,
  VIDEO_VIEW_ROUTE,
  FLAGGED_CONTENT_LIST_VIEW_ROUTE,
  USERS_SESSION_GRAPH_ROUTE,
  USERS_ROUTE,
  DEVICES_ROUTE,
  FAQ_VIEW_ROUTE,
} from '../routes';
import {
  ContentTypes,
  TimerFormatType,
  NavSection,
  RequiredFields,
  PushToType,
  SaveToType,
  StatusConstantType,
  EnvironmentConstantType,
  FlaggedContentType,
  PermissionTypes,
} from './types';

export * from './types';

// TODO: move all static images to /src and import dynamically
export const PLACEHOLDER_IMAGE = '/static/images/Image.svg';
export const PLACEHOLDER_VIDEO_THUMBNAIL = '/static/images/VideoThumbnail.svg';
export const PLACEHOLDER_VIDEO = '/static/images/Video.svg';
export const UPLOAD_IMAGE = '/static/images/UploadFile.svg';
export const NOT_FOUND_IMAGE = '/static/images/404.svg';
export const LOGO_IMAGE = '/static/images/icon.png';
export const CHEFIQ_LOGO_LIGHT_IMAGE = '/static/images/ChefiqLogo-light.png';
export const CHEFIQ_LOGO_DARK_IMAGE = '/static/images/ChefiqLogo-dark.png';

export const CONTENT_TYPES: ContentTypes = {
  RECIPE: 'RECIPE',
  INGREDIENT: 'INGREDIENT',
  VIDEO: 'VIDEO',
  HELP: 'HELP',
};

export const PERMISSIONS: PermissionTypes = {
  READ: 'READ',
  CREATE_UPDATE: 'CREATE_UPDATE',
  ADD_MEDIA: 'ADD_MEDIA',
  LOCK_DELETE: 'LOCK_DELETE',
  PUSH_TO_DEV: 'PUSH_TO_DEV',
  PUSH_TO_BETA: 'PUSH_TO_BETA',
  PUSH_TO_PROD: 'PUSH_TO_PROD',
  MANAGE_USERS: 'MANAGE_USERS',
  SWITCH_ENVIRONMENT: 'SWITCH_ENVIRONMENT',
};

export const NAV_SECTIONS: NavSection[] = [
  {
    subheader: 'CONTENT',
    items: [
      {
        title: 'Recipes',
        icon: CoffeeIcon,
        href: RECIPE_VIEW_ROUTE,
        requiredPermissions: {
          [CONTENT_TYPES.RECIPE]: [PERMISSIONS.READ, PERMISSIONS.CREATE_UPDATE],
        },
      },
      {
        title: 'Videos',
        icon: VideoIcon,
        href: VIDEO_VIEW_ROUTE,
        requiredPermissions: {
          [CONTENT_TYPES.VIDEO]: [PERMISSIONS.READ, PERMISSIONS.CREATE_UPDATE],
        },
      },
      {
        title: 'Ingredients',
        icon: FeatherIcon,
        href: INGREDIENT_VIEW_ROUTE,
        requiredPermissions: {
          [CONTENT_TYPES.INGREDIENT]: [
            PERMISSIONS.READ,
            PERMISSIONS.CREATE_UPDATE,
          ],
        },
      },
      // TODO: Update once Homepage is ready
      // {
      //   title: 'Homepage',
      //   icon: SmartphoneIcon,
      //   href: '/',
      // },
    ],
    requiredPermissions: {
      [CONTENT_TYPES.RECIPE]: [PERMISSIONS.READ, PERMISSIONS.CREATE_UPDATE],
      [CONTENT_TYPES.INGREDIENT]: [PERMISSIONS.READ, PERMISSIONS.CREATE_UPDATE],
      [CONTENT_TYPES.VIDEO]: [PERMISSIONS.READ, PERMISSIONS.CREATE_UPDATE],
    },
  },
  {
    subheader: 'SOCIAL MODERATION',
    items: [
      {
        title: 'Flagged Content',
        icon: FlagIcon,
        href: FLAGGED_CONTENT_LIST_VIEW_ROUTE,
      },
    ],
    requiredPermissions: {
      MODERATION: [PERMISSIONS.CREATE_UPDATE, PERMISSIONS.LOCK_DELETE],
    },
  },
  {
    subheader: 'CUSTOMER SUPPORT',
    items: [
      {
        title: 'Users',
        icon: UsersIcon,
        href: USERS_ROUTE,
      },
      {
        title: 'Devices',
        icon: SmartphoneIcon,
        href: DEVICES_ROUTE,
      },
    ],
    requiredPermissions: {
      TOOLS: [PERMISSIONS.READ],
    },
  },
  {
    subheader: 'HELP',
    items: [
      {
        title: 'FAQs',
        icon: HelpCircleIcon,
        href: FAQ_VIEW_ROUTE,
      },
    ],
    requiredPermissions: {
      [CONTENT_TYPES.HELP]: [PERMISSIONS.READ, PERMISSIONS.CREATE_UPDATE],
    },
  },
];

export const TOAST_VARIANT: ToastVariant = {
  ERROR: {
    variant: 'error',
  },
  SUCCESS: {
    variant: 'success',
  },
};

export const STATUS: StatusConstantType = {
  DRAFT: 'draft',
  IN_PROGRESS: 'in progress',
  IN_REVIEW: 'in review',
  APPROVED: 'approved',
};

export const STATUS_VALUES = Object.values(STATUS);

export const FLAGGED_CONTENT_TYPE: FlaggedContentType = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const ENVIRONMENT: EnvironmentConstantType = {
  DEV: 'dev',
  BETA: 'beta',
  PROD: 'prod',
};

export const FILTER_TAB_LABEL = {
  ALL: 'all',
  [STATUS.DRAFT]: 'my drafts',
  [STATUS.IN_PROGRESS]: 'in progress',
  [STATUS.IN_REVIEW]: 'in review',
  [ENVIRONMENT.DEV]: 'approved/on dev',
  [ENVIRONMENT.BETA]: 'on beta',
  [ENVIRONMENT.PROD]: 'on prod',
};

export const FLAGGED_CONTENT_TAB_LABEL = {
  [FLAGGED_CONTENT_TYPE.OPEN]: 'OPEN',
  [FLAGGED_CONTENT_TYPE.CLOSED]: 'CLOSED',
};

export const STATUS_UTILS: StatusUtilsType = {
  [STATUS.DRAFT]: { COLOR: 'myDrafts', LABEL: 'MY DRAFTS' },
  [STATUS.IN_PROGRESS]: { COLOR: 'inProgress', LABEL: 'IN PROGRESS' },
  [STATUS.IN_REVIEW]: { COLOR: 'inReview', LABEL: 'IN REVIEW' },
  [ENVIRONMENT.DEV]: { COLOR: 'onDev', LABEL: 'ON DEV' },
  [ENVIRONMENT.BETA]: { COLOR: 'onBeta', LABEL: 'ON BETA' },
  [ENVIRONMENT.PROD]: { COLOR: 'onProd', LABEL: 'ON PROD' },
};

export const STEP_CONDITION = {
  LINKED_DEVICE: {
    command: 'linked_device',
    rules: { compare_mode: 'or', linked: 0 },
    error_message: { code: 'no_device_linked', properties: { duration: 0 } },
  },
  DEVICE: {
    command: 'e_device',
    rules: { compare_mode: 'or', session_id_mismatch: 1, is_wifi_connected: 0 },
    error_message: {
      code: 'device_not_available',
      properties: { duration: 0 },
    },
  },
  TIMER: {
    command: 'e_timer',
    rules: { compare_mode: 'or', index: -999, timerFinished: 1 },
    error_message: {
      code: 'timer_running',
      properties: {
        duration: 0,
        buttons: [
          {
            button_text: 'Force complete the step',
            actions: [
              {
                command: 'force_stop_timer',
                properties: { index: -999 },
              },
            ],
          },
        ],
      },
    },
  },
};

export const COGNITO_GROUPS = {
  GUEST_USER: process.env.REACT_APP_AWS_COGNITO_GROUP_GUEST_USER || '',
  APP_USER: process.env.REACT_APP_AWS_COGNITO_GROUP_APP_USER || '',
  CULINARY_TEAM: process.env.REACT_APP_AWS_COGNITO_GROUP_CULINARY_TEAM || '',
  CULINARY_ADMIN: process.env.REACT_APP_AWS_COGNITO_GROUP_CULINARY_ADMIN || '',
  VIDEO_TEAM: process.env.REACT_APP_AWS_COGNITO_GROUP_VIDEO_TEAM || '',
  VIDEO_ADMIN: process.env.REACT_APP_AWS_COGNITO_GROUP_VIDEO_ADMIN || '',
  APP_ADMIN: process.env.REACT_APP_AWS_COGNITO_GROUP_APP_ADMIN || '',
  CUSTOMER_SUPPORT_TEAM:
    process.env.REACT_APP_AWS_COGNITO_GROUP_CUSTOMER_SUPPORT_TEAM || '',
  FIRMWARE_TEAM: process.env.REACT_APP_AWS_COGNITO_GROUP_FIRMWARE_TEAM || '',
  MARKETING_TEAM: process.env.REACT_APP_AWS_COGNITO_GROUP_MARKETING_TEAM || '',
};

export const COGNITO_GROUPS_MAP = {
  [COGNITO_GROUPS.GUEST_USER]: 'Guest User',
  [COGNITO_GROUPS.APP_USER]: 'App User',
  [COGNITO_GROUPS.VIDEO_TEAM]: 'Video Team',
  [COGNITO_GROUPS.VIDEO_ADMIN]: 'Video Admin',
  [COGNITO_GROUPS.CULINARY_TEAM]: 'Culinary Team',
  [COGNITO_GROUPS.CULINARY_ADMIN]: 'Culinary Admin',
  [COGNITO_GROUPS.APP_ADMIN]: 'App Admin',
  [COGNITO_GROUPS.CUSTOMER_SUPPORT_TEAM]: 'Customer Support Team',
  [COGNITO_GROUPS.FIRMWARE_TEAM]: 'Firmware Team',
  [COGNITO_GROUPS.MARKETING_TEAM]: 'Marketing Team',
};

export const ASSETS_BUCKET_ACL = 'bucket-owner-full-control';

export const STEP_BUTTON_TEXT = 'MARK COMPLETE';

export const REQUIRED_FIELDS: RequiredFields = {
  [CONTENT_TYPES.RECIPE]: {
    [STATUS.DRAFT]: ['name', 'skillLevel'],
    [STATUS.IN_PROGRESS]: [
      'name',
      'skillLevel',
      'groupedIngredients',
      'groupedSteps',
      'activeTime',
      'totalTime',
      'yieldNumber',
      'yieldType',
    ],
  },
  [CONTENT_TYPES.INGREDIENT]: {
    [STATUS.IN_PROGRESS]: ['name', 'namePlural'],
  },
  [CONTENT_TYPES.VIDEO]: {
    [STATUS.IN_PROGRESS]: ['name', 'videoCategoryId', 'video'],
    [STATUS.IN_REVIEW]: ['name', 'videoCategoryId', 'video'],
  },
};

export const SAVE_TO: SaveToType = {
  [CONTENT_TYPES.RECIPE]: {
    [STATUS.DRAFT]: { text: 'SAVE TO IN PROGRESS', status: STATUS.IN_PROGRESS },
    [STATUS.IN_PROGRESS]: {
      text: 'SAVE TO IN REVIEW',
      status: STATUS.IN_REVIEW,
    },
    [STATUS.IN_REVIEW]: { text: 'PUSH TO DEV', status: STATUS.APPROVED },
    [STATUS.APPROVED]: { text: 'PUSH TO BETA', status: STATUS.APPROVED },
    [ENVIRONMENT.BETA]: { text: 'PUSH TO PROD', status: STATUS.APPROVED },
  },
  [CONTENT_TYPES.INGREDIENT]: {
    [STATUS.IN_PROGRESS]: {
      text: 'PUSH TO DEV',
      status: STATUS.APPROVED,
    },
    [STATUS.APPROVED]: { text: 'PUSH TO BETA', status: STATUS.APPROVED },
    [ENVIRONMENT.BETA]: { text: 'PUSH TO PROD', status: STATUS.APPROVED },
  },
  [CONTENT_TYPES.VIDEO]: {
    [STATUS.IN_PROGRESS]: {
      text: 'SAVE TO IN REVIEW',
      status: STATUS.IN_REVIEW,
    },
    [STATUS.IN_REVIEW]: { text: 'PUSH TO DEV', status: STATUS.APPROVED },
    [STATUS.APPROVED]: { text: 'PUSH TO BETA', status: STATUS.APPROVED },
    [ENVIRONMENT.BETA]: { text: 'PUSH TO PROD', status: STATUS.APPROVED },
  },
};

export const PUSH_TO: Omit<PushToType, 'HELP'> = {
  [CONTENT_TYPES.RECIPE]: {
    [STATUS.DRAFT]: ['current-user', COGNITO_GROUPS.APP_ADMIN],
    [STATUS.IN_PROGRESS]: ['current-user', COGNITO_GROUPS.APP_ADMIN],
    [STATUS.IN_REVIEW]: [
      COGNITO_GROUPS.CULINARY_ADMIN,
      COGNITO_GROUPS.APP_ADMIN,
    ],
    [STATUS.APPROVED]: [
      COGNITO_GROUPS.CULINARY_ADMIN,
      COGNITO_GROUPS.APP_ADMIN,
    ],
  },
  [CONTENT_TYPES.INGREDIENT]: {
    [STATUS.IN_PROGRESS]: ['current-user', COGNITO_GROUPS.APP_ADMIN],
    [STATUS.APPROVED]: [
      COGNITO_GROUPS.CULINARY_ADMIN,
      COGNITO_GROUPS.APP_ADMIN,
    ],
  },
  [CONTENT_TYPES.VIDEO]: {
    [STATUS.IN_PROGRESS]: ['current-user', COGNITO_GROUPS.APP_ADMIN],
    [STATUS.APPROVED]: [COGNITO_GROUPS.VIDEO_ADMIN, COGNITO_GROUPS.APP_ADMIN],
  },
};

export const TIMER_FORMATS: TimerFormatType = {
  hourFormat: ['hr', 'hrs', 'hour', 'hours'],
  minuteFormat: ['min', 'mins', 'minute', 'minutes'],
  secondFormat: ['sec', 'secs', 'second', 'seconds'],
};

export const HOURS_TIME_FORMATS = ['hr', 'hrs', 'hour', 'hours'];

export const MINUTES_TIME_FORMATS = ['min', 'mins', 'minute', 'minutes'];

export const SECONDS_TIME_FORMATS = ['sec', 'secs', 'second', 'seconds'];

export const ALL_TIME_FORMATS = [
  ...HOURS_TIME_FORMATS,
  ...MINUTES_TIME_FORMATS,
  ...SECONDS_TIME_FORMATS,
];

export const VIDEO_S3_CLOUDFRONT_MAP: Record<string, string> = {
  'chefiq-howtos.s3-website-us-east-1.amazonaws.com':
    'd38z0n8bh9c895.cloudfront.net',
  'chefiq-videos.s3.amazonaws.com': 'videos.chefiq.com',
  'chefiq-static-assets.s3.amazonaws.com': 'd3bhy7k4177v9t.cloudfront.net',
  's3.amazonaws.com/chefiq-recipes': 'd1y06f4dn4rjc0.cloudfront.net',
  'chefiq-recipes.s3-website-us-east-1.amazonaws.com':
    'd1y06f4dn4rjc0.cloudfront.net',
  'chefiq-assets.s3.amazonaws.com': 'assets-old.chefiq.com',
};

export const ROLE_PERMISSIONS = {
  [CONTENT_TYPES.RECIPE]: {
    [PERMISSIONS.READ]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_TEAM,
      COGNITO_GROUPS.CULINARY_ADMIN,
      COGNITO_GROUPS.VIDEO_TEAM,
      COGNITO_GROUPS.VIDEO_ADMIN,
      COGNITO_GROUPS.APP_USER,
    ],
    [PERMISSIONS.CREATE_UPDATE]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_TEAM,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.ADD_MEDIA]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.VIDEO_TEAM,
      COGNITO_GROUPS.VIDEO_ADMIN,
      COGNITO_GROUPS.CULINARY_TEAM,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.LOCK_DELETE]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_DEV]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_BETA]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_PROD]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
  },
  [CONTENT_TYPES.INGREDIENT]: {
    [PERMISSIONS.READ]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_TEAM,
      COGNITO_GROUPS.CULINARY_ADMIN,
      COGNITO_GROUPS.VIDEO_TEAM,
      COGNITO_GROUPS.VIDEO_ADMIN,
    ],
    [PERMISSIONS.CREATE_UPDATE]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_TEAM,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.ADD_MEDIA]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.VIDEO_TEAM,
      COGNITO_GROUPS.VIDEO_ADMIN,
      COGNITO_GROUPS.CULINARY_TEAM,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.LOCK_DELETE]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_DEV]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_BETA]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_PROD]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_ADMIN,
    ],
  },
  [CONTENT_TYPES.VIDEO]: {
    [PERMISSIONS.READ]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CULINARY_TEAM,
      COGNITO_GROUPS.CULINARY_ADMIN,
      COGNITO_GROUPS.VIDEO_TEAM,
      COGNITO_GROUPS.VIDEO_ADMIN,
    ],
    [PERMISSIONS.CREATE_UPDATE]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.VIDEO_TEAM,
      COGNITO_GROUPS.VIDEO_ADMIN,
    ],
    [PERMISSIONS.LOCK_DELETE]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.VIDEO_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_DEV]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.VIDEO_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_BETA]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.VIDEO_ADMIN,
    ],
    [PERMISSIONS.PUSH_TO_PROD]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.VIDEO_ADMIN,
    ],
  },
  [CONTENT_TYPES.HELP]: {
    [PERMISSIONS.READ]: [COGNITO_GROUPS.APP_ADMIN],
    [PERMISSIONS.CREATE_UPDATE]: [COGNITO_GROUPS.APP_ADMIN],
    [PERMISSIONS.SWITCH_ENVIRONMENT]: [COGNITO_GROUPS.APP_ADMIN],
  },
  MODERATION: {
    [PERMISSIONS.CREATE_UPDATE]: [COGNITO_GROUPS.APP_ADMIN],
    [PERMISSIONS.LOCK_DELETE]: [COGNITO_GROUPS.APP_ADMIN],
  },
  TOOLS: {
    [PERMISSIONS.READ]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.FIRMWARE_TEAM,
      COGNITO_GROUPS.CUSTOMER_SUPPORT_TEAM,
    ],
    [PERMISSIONS.MANAGE_USERS]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.CUSTOMER_SUPPORT_TEAM,
    ],
    [PERMISSIONS.SWITCH_ENVIRONMENT]: [
      COGNITO_GROUPS.APP_ADMIN,
      COGNITO_GROUPS.FIRMWARE_TEAM,
    ],
  },
};

export const PUSH_TO_REQUIRED_PERMISSIONS = {
  [STATUS.DRAFT]: PERMISSIONS.CREATE_UPDATE,
  [STATUS.IN_PROGRESS]: PERMISSIONS.CREATE_UPDATE,
  [STATUS.IN_REVIEW]: PERMISSIONS.PUSH_TO_DEV,
  [STATUS.APPROVED]: PERMISSIONS.PUSH_TO_BETA,
};

// TODO: Get correct type mapping.
export const COOKING_HISTORY_TYPE_MAP: Record<number, string> = {
  0: 'Quick Set - Preset',
  1: 'Favorite',
  2: 'Quick Set - Manual',
  3: 'Guided Cooking',
  4: 'Times & Temps',
};
