import { useState } from 'react';

import { ModalStateProps } from './types';

const useModalState = (props?: ModalStateProps) => {
  const { initialOpen = false } = props || {};
  const [isOpen, setIsOpen] = useState(initialOpen);

  const onOpen = () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  const onToggle = () => setIsOpen(!isOpen);

  return { onOpen, onClose, isOpen, onToggle };
};

export default useModalState;
