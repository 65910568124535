import {
  intervalToDuration,
  addSeconds,
  format as formatDate,
  differenceInDays,
  differenceInHours,
  isValid,
} from 'date-fns';
import { orderBy as _orderBy, groupBy as _groupBy } from 'lodash';

import {
  ENVIRONMENT,
  EnvironmentType,
  FILTER_TAB_LABEL,
  STATUS,
  STATUS_UTILS,
} from 'src/constants';
import { StatusUtilsIndex, StatusUtilsOutput } from 'src/types';

import {
  ResizeVideoUrlInput,
  CreateStorageKeyInput,
  GetAvatarNameInput,
  GetConcatStringInput,
  GetEnvironmentInput,
  GetNameFromUserIdInput,
  GetRandomColorInput,
  GetStatusConfigInput,
  ResizeImageInput,
  ViewCardTitleInput,
} from './types';

const { REACT_APP_AWS_CLOUDFRONT_URL } = process.env;

export const convertSecondsToTime = (seconds: number) => {
  const tempDate = new Date(0);
  const {
    days = 0,
    hours = 0,
    ...rest
  } = intervalToDuration({
    start: tempDate,
    end: addSeconds(tempDate, seconds),
  });

  const durationIndex = {
    ...rest,
    days: 0,
    hours: hours + 24 * days,
  };

  return durationIndex;
};

export const convertTimeToSeconds = (
  duration: Pick<Duration, 'hours' | 'minutes' | 'seconds'>,
) => {
  let totalSeconds = 0;

  totalSeconds += duration.hours ? duration.hours * 60 * 60 : 0;
  totalSeconds += duration.minutes ? duration.minutes * 60 : 0;
  totalSeconds += duration.seconds ? duration.seconds : 0;

  return totalSeconds;
};

export const formatDuration = (
  time?: number | null,
  displaySeconds?: boolean,
) => {
  if (typeof time === 'number') {
    const durationIndex = convertSecondsToTime(time);

    let formattedDuration = '';

    formattedDuration += durationIndex.hours
      ? `${durationIndex.hours} hr `
      : '';
    formattedDuration += durationIndex.minutes
      ? `${durationIndex.minutes} min`
      : '';

    if (displaySeconds) {
      formattedDuration += durationIndex.seconds
        ? ` ${durationIndex.seconds} sec`
        : '';
    }

    return formattedDuration.trim();
  }
  return '';
};

export const formatTimestamp = (timestamp: string) => {
  if (!isValid(new Date(timestamp))) return '';
  return formatDate(new Date(timestamp), 'MM/dd/yyyy HH:mm');
};

export const getStatusConfig = ({ status, env }: GetStatusConfigInput) => {
  if (!status) return {};

  let statusConfig: StatusUtilsOutput =
    STATUS_UTILS[status as StatusUtilsIndex];

  if (status === STATUS.APPROVED)
    statusConfig = STATUS_UTILS[env as StatusUtilsIndex];

  return { label: statusConfig?.LABEL || '', color: statusConfig?.COLOR };
};

export const getEnvironment = ({ selectedTab }: GetEnvironmentInput) => {
  if (selectedTab === FILTER_TAB_LABEL[ENVIRONMENT.BETA]) {
    return ENVIRONMENT.BETA;
  }

  if (selectedTab === FILTER_TAB_LABEL[ENVIRONMENT.PROD]) {
    return ENVIRONMENT.PROD;
  }

  return ENVIRONMENT.DEV;
};

export const getAvatarName = ({ userName }: GetAvatarNameInput) => {
  const [firstName, lastName] = userName.split(' ');

  if (!firstName || !lastName) return '';

  if (firstName && lastName)
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;

  return firstName.charAt(0);
};

export const getRandomColor = ({ userName }: GetRandomColorInput) => {
  let hash = 0;
  if (userName.length === 0) return 'grey';
  for (let i = 0; i < userName.length; i++) {
    hash = userName.charCodeAt(i) + ((hash << 3) - hash);
    hash &= hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

export const getConcatString = (stringList: GetConcatStringInput) =>
  Array.isArray(stringList) ? stringList.filter((e) => e).join(' ') : '';

export const getViewCardTitle = ({
  recipeNumber,
  name,
}: ViewCardTitleInput) => {
  const title = recipeNumber ? `#${recipeNumber}. ${name || ''}` : name || '';
  return title;
};

export const getIngredientViewCardTitle = ({
  name,
  pre,
  post
}: ViewCardTitleInput) => {
  if (!name) return '';

  const preText = pre? `${pre} ` : '';
  const postText = post? ` ${post}` : '';
  return `${preText}${name}${postText}`;
};

const getCloudFrontUrl = (bucketName: string) => {
  if (bucketName === 'icons') return 'd2ssxs0pm6cdlf';
  if (bucketName === 'images') return 'd2boay4pj6b2l0';
  if (bucketName === 'howtos') return 'd38z0n8bh9c895';
  if (bucketName === 'recipes') return 'd1y06f4dn4rjc0';
  return '';
};

const getLastItem = (path: string) => {
  return path.substring(path.lastIndexOf('/') + 1);
};

export const newResizedImageUrl = ({
  imageUrl,
  // size = IS_TABLET ? '1024' : '512',
  size = '256',
}: ResizeImageInput) => {
  const fileName = getLastItem(imageUrl);
  const domainPath = imageUrl.replace(fileName, '');
  const resizeUrl = `${domainPath}${size}/${fileName}`;
  return resizeUrl;
};

export const resizedImageUrl = ({
  imageUrl,
  size = '256',
  format,
}: ResizeImageInput) => {
  if (imageUrl) {
    const newCloudFrontUrl = [
      'https://assets.chefiq.com',
      'https://assets.dev.chefiq.com',
      'https://assets.sandbox.chefiq.com',
    ];

    const hasNewLink = newCloudFrontUrl.some((url) => imageUrl.includes(url));
    if (hasNewLink) {
      return newResizedImageUrl({ imageUrl, size });
    }
    const s3WebUrl = '.s3.amazonaws.com';
    const cloudfrontUrl = '.chefiq.com';
    const hascloudFrontUrl = imageUrl.includes(cloudfrontUrl);
    let bucket;
    let resizeUrl;

    if (hascloudFrontUrl) {
      const arr = imageUrl.split(cloudfrontUrl);
      const splitedUrl = arr[0] || '';
      const path = arr[1] || '';
      const index = splitedUrl.lastIndexOf('/');
      const bucketName = splitedUrl.substring(index + 1);
      const cloudfront = getCloudFrontUrl(bucketName);
      resizeUrl = `https://${cloudfront}.cloudfront.net${path}`;
      const queryStringParam = path.includes('?')
        ? `&bucket=${bucketName}&cloudfront=${cloudfront}`
        : `?chefiq&bucket=${bucketName}&cloudfront=${cloudfront}`;
      const lastIndex = resizeUrl.lastIndexOf('/');
      resizeUrl = `${resizeUrl.slice(0, lastIndex)}/${size}${resizeUrl.slice(
        lastIndex,
      )}${queryStringParam}`;
      if (format) resizeUrl += `&format=${format}`;
      return resizeUrl;
    }
    const arr = imageUrl.split('chefiq-');
    const splitedUrl = arr[1] || '';
    const splitedUrlArr = splitedUrl.split('/');
    const bucketName = splitedUrlArr[0] || '';

    const hasS3WebUrl = bucketName.includes(s3WebUrl);
    if (hasS3WebUrl) {
      bucket = bucketName.replace(s3WebUrl, '');
    } else {
      bucket = bucketName;
    }
    const cloudfront = getCloudFrontUrl(bucket);
    const pathArr = imageUrl.split(`chefiq-${bucketName}`);
    const path = pathArr[1] || '';
    resizeUrl = `https://${cloudfront}.cloudfront.net${path}`;
    const queryStringParam = path.includes('?')
      ? `&bucket=${bucket}&cloudfront=${cloudfront}`
      : `?chefiq&bucket=${bucket}&cloudfront=${cloudfront}`;
    const lastIndex = resizeUrl.lastIndexOf('/');
    resizeUrl = `${resizeUrl.slice(0, lastIndex)}/${size}${resizeUrl.slice(
      lastIndex,
    )}${queryStringParam}`;
    if (format) resizeUrl += `&format=${format}`;
    return resizeUrl;
  }
  return '';
};

export const createStorageKey = ({
  assetType,
  objectType,
  fileExtension,
  id = '',
  parentId = '',
  filename = '',
}: CreateStorageKeyInput) => {
  let storageKey = `${assetType}s/`;
  const localFilename =
    filename || (assetType === 'image' ? 'cover_photo' : 'cover_video');

  if (objectType === 'recipe') storageKey += `recipes/${id}/`;
  else if (objectType === 'recipe-step')
    storageKey += `recipes/${parentId}/steps/${id}/`;
  else if (objectType === 'ingredient') storageKey += 'ingredients/';

  storageKey += `${localFilename}-${Date.now()}.${fileExtension}`;

  return storageKey;
};

export const getFileMetadata = (url?: string | null) => {
  const result = { name: '' };

  if (typeof url === 'string' && url.includes('/')) {
    const arr = url.split('/');
    const metadata = arr[arr.length - 1].split('?');
    result.name = metadata[0];
  }

  return result;
};

export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

export const getNameFromUserId = ({
  userId,
  usersObject,
}: GetNameFromUserIdInput) => {
  if (!userId) return '';

  const { firstName, lastName } = usersObject?.[userId] || {};

  const userName = getConcatString([firstName, lastName]);

  return userName;
};

export const getS3FileUrl = (key: string) =>
  [REACT_APP_AWS_CLOUDFRONT_URL, key].join('/');

export const resizeVideoUrl = ({ video, size }: ResizeVideoUrlInput) => {
  if (!video) {
    return null;
  }

  const lastIndex = video.lastIndexOf('/');
  const url = video.slice(0, lastIndex + 1);
  const queryString = video.slice(lastIndex);
  return `${url}${size}${queryString}`;
};

export const groupByDate = <T>(
  data: T[],
  dateKey: keyof T,
  sortDir: 'asc' | 'desc' = 'desc',
) => {
  const result: Record<string, { date: string; dateText: string; data: T[] }> =
    {};

  data.forEach((item) => {
    const date = item[dateKey] as unknown as string;
    const dayDifference = differenceInDays(
      new Date(),
      new Date(new Date(date).toDateString()),
    );
    const dateText =
      // eslint-disable-next-line no-nested-ternary
      dayDifference === 0
        ? 'Today'
        : dayDifference === 1
        ? 'Yesterday'
        : formatDate(new Date(date), 'MMMM d, yyyy');

    if (!result[dateText]) {
      result[dateText] = {
        date: formatDate(new Date(date), 'yyyy-MM-dd'),
        dateText,
        data: [],
      };
    }
    result[dateText].data.push(item);
  });

  return _orderBy(
    Object.values(result).map((group) => ({
      ...group,
      data: _orderBy(group.data, dateKey, sortDir),
    })),
    'date',
    sortDir,
  );
};

export const formatRelativeTimestamp = (
  timestamp: string,
  dayDifferenceBreakpoint = 0,
) => {
  if (!isValid(new Date(timestamp))) return '';

  const now = new Date();
  const date = new Date(timestamp);

  const dayDifference = differenceInDays(now, date);

  if (dayDifference <= dayDifferenceBreakpoint) {
    const hourDifference = differenceInHours(now, date);

    if (hourDifference === 0) return 'Just now';

    const hoursText =
      hourDifference > 1 || hourDifference < 0 ? 'hours' : 'hour';

    const absHourDifference = Math.abs(hourDifference);
    return hourDifference > 0
      ? `${absHourDifference} ${hoursText} ago`
      : `In ${absHourDifference} ${hoursText} time`;
  }

  return formatDate(new Date(timestamp), 'h:mm a');
};

export const getNextPushToEnv = (currentEnv: EnvironmentType) => {
  const envs = [ENVIRONMENT.DEV, ENVIRONMENT.BETA, ENVIRONMENT.PROD];
  const currentIndex = envs.indexOf(currentEnv);

  if (currentIndex === -1 || currentIndex === envs.length - 1) {
    return currentEnv;
  }
  return envs[currentIndex + 1];
};

export const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isReadOnlyEnv = (env: EnvironmentType) => {
  return env === ENVIRONMENT.PROD || env === ENVIRONMENT.BETA;
};

export const downloadAsCSV = (data: string[], filename = 'data.csv') => {
  const csvContent = `data:text/csv;charset=utf-8,${data.join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const populateUrl = (url: string, params: Record<string, string>) => {
  let populatedUrl = url;
  Object.keys(params).forEach((key) => {
    populatedUrl = populatedUrl.replace(`:${key}`, params[key]);
  });
  return populatedUrl;
};

export const getPageTitleFromPath = (
  path: string,
  pages: Record<string, string>,
  fallback: string,
) => {
  const entries = Object.entries(pages);

  for (let i = 0; i < entries.length; i++) {
    const re = new RegExp(entries[i][1].replace(/\/:\w+/g, '\\/[\\w\\d-]+'));

    if (re.test(path)) {
      return entries[i][0];
    }
  }
  return fallback;
};

export const getBreadcrumbsFromPath = (
  path: string,
  pages: Record<string, string>,
  params: Record<string, string>,
) => {
  const results: Array<{ text: string; url: string }> = [];
  const entries = Object.entries(pages);

  for (let i = 0; i < entries.length; i++) {
    const re = new RegExp(entries[i][1].replace(/\/:\w+/g, '\\/[\\w\\d-]+'));

    if (re.test(path)) {
      const result = {
        text: entries[i][0],
        url: entries[i][1],
      };

      const paramKeys = entries[i][1].match(/:\w+/g) || [];
      console.log(paramKeys, params);

      paramKeys.forEach((key) => {
        result.url = result.url.replace(key, params[key.replace(':', '')]);
      });

      results.push(result);
    }
  }
  return results;
};
