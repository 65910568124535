import { useState, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import camelcaseKeys from 'camelcase-keys';
import { isEqual as _isEqual, get as _get } from 'lodash';

import { useGraphqlToastHandling, usePrevious } from 'src/hooks';

import { UseQueryInput } from './types';

const useQueryWithErrorHandling = <T extends { [key: string]: unknown }>({
  query,
  queryName,
  options = {},
}: UseQueryInput) => {
  const { onCompleted, onError } = useGraphqlToastHandling({ options });
  const queryData = useQuery<T>(query, {
    fetchPolicy: 'cache-and-network',
    ...options,
    onCompleted: (res) => {
      const { success, message, data } =
        (res[queryName] as Record<string, unknown>) || {};
      onCompleted({
        success: typeof success === 'boolean' ? success : true,
        message: message as string,
        data,
      });
    },
    onError,
  });

  const { data, ...restQueryData } = queryData || {};

  const previousData = usePrevious(data);
  const [camelCasedData, setCamelCasedData] = useState<T | null>(null);

  const isDataEqual = useMemo(
    () => _isEqual(previousData, data),
    [data, previousData],
  );

  useEffect(() => {
    if (!isDataEqual && data)
      setCamelCasedData(camelcaseKeys(data, { deep: true }));
  }, [data, isDataEqual]);

  const formattedQueryData = useMemo(
    () => ({ data: camelCasedData, ...restQueryData }),
    [camelCasedData, restQueryData],
  );

  return formattedQueryData;
};

export default useQueryWithErrorHandling;
