import {
  isValidElement,
  cloneElement,
  Children,
  useCallback,
  ReactNode,
  ReactElement,
  useMemo,
} from 'react';

import { Redirect } from 'react-router';

import { COGNITO_GROUPS, RECIPE_VIEW_ROUTE, USERS_ROUTE } from 'src/constants';
import { useAuth } from 'src/hooks';
import usePermissions from 'src/hooks/global/usePermissions';

import { RoleGuardProps } from './types';

const DEFAULT_ROLE_ROUTES = {
  [COGNITO_GROUPS.CUSTOMER_SUPPORT_TEAM]: USERS_ROUTE,
  [COGNITO_GROUPS.FIRMWARE_TEAM]: USERS_ROUTE,
};

const RoleGuard = ({
  children,
  requiredPermissions = {},
  requirementType = 'all',
  disabledKey = 'disabled',
  disabledBehavior = 'disable',
  isDisabledOverride,
}: RoleGuardProps) => {
  const { user } = useAuth();
  const { cognitoGroups = [] } = user || {};

  const permissions = usePermissions(requiredPermissions);

  const hasRequiredPermissions = useMemo(() => {
    if (requirementType === 'all') {
      return Object.values(permissions).every((type) =>
        Object.values(type).every((value) => value),
      );
    }
    return Object.values(permissions).some((type) =>
      Object.values(type).some((value) => value),
    );
  }, [permissions, requirementType]);

  const mapNodes: (children: ReactNode) => ReactNode = useCallback(
    (nodes: ReactNode) => {
      return Children.map(nodes, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child as ReactElement, {
            [disabledKey]: isDisabledOverride || !hasRequiredPermissions,
            children: mapNodes(child.props.children),
          });
        }
        return child;
      });
    },
    [disabledKey, hasRequiredPermissions, isDisabledOverride],
  );

  if (disabledBehavior === 'redirect' && !hasRequiredPermissions) {
    const route =
      Object.entries(DEFAULT_ROLE_ROUTES).find(([key]) =>
        cognitoGroups.includes(key),
      )?.[1] || RECIPE_VIEW_ROUTE;

    return <Redirect to={route} />;
  }

  if (disabledBehavior === 'hide' && !hasRequiredPermissions) return null;

  return <>{mapNodes(children)}</>;
};

export default RoleGuard;
