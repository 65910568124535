import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './index.style';
import { LoadingType } from './types';

const Loading = ({
  variant = 'middle',
  className,
  withSidebar,
  loaderClassName,
  ...rest
}: LoadingType) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        {
          [classes.inline]: variant === 'inline',
          [classes.middle]: variant === 'middle',
          [classes.withSidebar]: withSidebar,
        },
        className,
      )}
    >
      <Box>
        <CircularProgress
          color="secondary"
          className={loaderClassName}
          {...rest}
        />
      </Box>
    </div>
  );
};

export default Loading;
