import { useState, useMemo, useEffect, useCallback } from 'react';

import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  MutationFunctionOptions,
  MutationTuple,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import camelcaseKeys from 'camelcase-keys';
import { isEqual as _isEqual } from 'lodash';

import { useGraphqlToastHandling, usePrevious } from 'src/hooks';

import { UseMutationInput } from './types';

const useMutationWithErrorHandling = <T extends { [key: string]: unknown }>({
  mutation,
  mutationName,
  options = {},
}: UseMutationInput) => {
  const { onCompleted, onError } = useGraphqlToastHandling({ options });
  const [mutationFunc, mutationData] = useMutation<T>(mutation, {
    ...options,
    onCompleted: (res) => {
      const { success, message, data } =
        (res[mutationName] as Record<string, unknown>) || {};
      onCompleted({
        success: typeof success === 'boolean' ? success : true,
        message: message as string,
        data,
      });
    },
    onError,
  });

  const { data, ...restQueryData } = mutationData || {};

  const previousData = usePrevious(data);
  const [camelCasedData, setCamelCasedData] = useState<T | null>(null);

  const isDataEqual = useMemo(
    () => _isEqual(previousData, data),
    [data, previousData],
  );

  useEffect(() => {
    if (!isDataEqual && data) {
      setCamelCasedData(camelcaseKeys(data, { deep: true }));
    }
  }, [data, isDataEqual]);

  const formattedMutationData = useMemo(
    () => ({ data: camelCasedData, ...restQueryData }),
    [camelCasedData, restQueryData],
  );

  const modifiedMutationFunc = useCallback(
    (
      params?: MutationFunctionOptions<
        T,
        OperationVariables,
        DefaultContext,
        ApolloCache<any> // eslint-disable-line @typescript-eslint/no-explicit-any
      >,
    ) =>
      new Promise((resolve, reject) => {
        mutationFunc(params)
          .then((mutationFuncData) => {
            const camelCasedMutationData = camelcaseKeys(mutationFuncData, {
              deep: true,
            });
            resolve(camelCasedMutationData);
          })
          .catch((error) => {
            onError(error as ApolloError);
            reject(error);
          });
      }),
    [mutationFunc, onError],
  );

  const result = useMemo(
    () =>
      [modifiedMutationFunc, formattedMutationData] as MutationTuple<
        T,
        OperationVariables
      >,
    [formattedMutationData, modifiedMutationFunc],
  );

  return result;
};

export default useMutationWithErrorHandling;
