import { useMediaQuery } from '@material-ui/core';

import { betaClient, prodClient } from 'src/apollo';
import {
  ENVIRONMENT,
  FILTER_TAB_LABEL,
  STATUS,
  StatusType,
} from 'src/constants';
import { useAuth } from 'src/hooks';

import { GetAllQueryConfigInput, InputConfigType, OptionsType } from './types';

const useGetAllQueryConfig = ({
  tab,
  afterIndex,
  inputVariables,
}: GetAllQueryConfigInput) => {
  const largerThanFullHD = useMediaQuery('(min-width:2000px)');
  const { user } = useAuth();
  const { id: userId } = user || {};
  const getInputConfig: InputConfigType = {
    ...inputVariables,
  };

  if (
    ![
      FILTER_TAB_LABEL.ALL,
      FILTER_TAB_LABEL[ENVIRONMENT.BETA],
      FILTER_TAB_LABEL[ENVIRONMENT.PROD],
    ].includes(tab)
  )
    getInputConfig.status = tab as StatusType;

  if (FILTER_TAB_LABEL[STATUS.DRAFT].includes(tab))
    getInputConfig.created_by = userId;
  else if (
    [
      FILTER_TAB_LABEL[ENVIRONMENT.BETA],
      FILTER_TAB_LABEL[ENVIRONMENT.PROD],
    ].includes(tab)
  )
    getInputConfig.status = STATUS.APPROVED;

  const fetchMoreInputConfig: InputConfigType = { ...getInputConfig };

  getInputConfig.limit = largerThanFullHD ? 30 : 20;

  if (afterIndex) fetchMoreInputConfig.after = afterIndex;

  const getOptions: OptionsType = {
    variables: {
      input: getInputConfig,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
  };

  const fetchMoreOptions: OptionsType = {
    variables: {
      input: fetchMoreInputConfig,
    },
  };

  if (tab === FILTER_TAB_LABEL[ENVIRONMENT.BETA]) {
    getOptions.client = betaClient;
    fetchMoreOptions.client = betaClient;
  } else if (tab === FILTER_TAB_LABEL[ENVIRONMENT.PROD]) {
    getOptions.client = prodClient;
    fetchMoreOptions.client = prodClient;
  }

  return { getOptions, fetchMoreOptions };
};

export default useGetAllQueryConfig;
