import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import * as authActions from 'src/redux/auth/thunk';
import { RootState } from 'src/redux/store';
import { SetAuthPayload } from 'src/types/global';

const useAuth = () => {
  const dispatch = useDispatch();
  const { isInitialized, isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth,
  );

  const setAuth = useCallback(
    (value: SetAuthPayload) => {
      dispatch(authActions.setAuth(value));
    },
    [dispatch],
  );

  return {
    isInitialized,
    isAuthenticated,
    user,
    setAuth,
  };
};

export default useAuth;
