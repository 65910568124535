import { Suspense, Fragment, lazy } from 'react';

import { Switch, Redirect, Route } from 'react-router-dom';

import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import Loading from 'src/components/Loading';
import RoleGuard from 'src/components/RoleGuard';
import {
  ROUTE_404,
  LOGIN_ROUTE,
  RECIPE_VIEW_ROUTE,
  RECIPE_EDIT_ROUTE,
  INGREDIENT_VIEW_ROUTE,
  INGREDIENT_EDIT_ROUTE,
  VIDEO_VIEW_ROUTE,
  VIDEO_EDIT_ROUTE,
  FLAGGED_CONTENT_LIST_VIEW_ROUTE,
  FLAGGED_CONTENT_TICKET_VIEW_ROUTE,
  FLAGGED_CONTENT_PROFILE_VIEW_ROUTE,
  PERMISSIONS,
  USERS_ROUTE,
  CONTENT_TYPES,
  CUSTOMER_SUPPORT_ROUTE,
  FAQ_VIEW_ROUTE,
} from 'src/constants';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoginView from 'src/views/Login';

import { RouteType } from './types';

export const renderRoutes = (routes: RouteType[] = []) => (
  <Suspense fallback={<Loading />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        const guardProps = route.guardProps || {};

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard {...guardProps}>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export const routes: RouteType[] = [
  {
    exact: true,
    path: LOGIN_ROUTE,
    component: LoginView,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: ROUTE_404,
    component: lazy(() => import('src/views/Errors/NotFoundView')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to={RECIPE_VIEW_ROUTE} />,
      },
      {
        exact: true,
        path: RECIPE_EDIT_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            [CONTENT_TYPES.RECIPE]: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/Recipe/EditRecipe')),
      },
      {
        exact: true,
        path: RECIPE_VIEW_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            [CONTENT_TYPES.RECIPE]: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/Recipe/RecipeView')),
      },
      {
        exact: true,
        path: INGREDIENT_VIEW_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            [CONTENT_TYPES.INGREDIENT]: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/Ingredient/IngredientView')),
      },
      {
        exact: true,
        path: INGREDIENT_EDIT_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            [CONTENT_TYPES.INGREDIENT]: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/Ingredient/EditIngredient')),
      },
      {
        exact: true,
        path: VIDEO_VIEW_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            [CONTENT_TYPES.VIDEO]: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/Video/VideoView')),
      },
      {
        exact: true,
        path: VIDEO_EDIT_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            [CONTENT_TYPES.VIDEO]: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/Video/EditVideo')),
      },
      {
        exact: true,
        path: FLAGGED_CONTENT_LIST_VIEW_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            MODERATION: [PERMISSIONS.CREATE_UPDATE, PERMISSIONS.LOCK_DELETE],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(
          () => import('src/views/FlaggedContent/FlaggedContentList'),
        ),
      },
      {
        exact: true,
        path: FLAGGED_CONTENT_TICKET_VIEW_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            MODERATION: [PERMISSIONS.CREATE_UPDATE, PERMISSIONS.LOCK_DELETE],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/FlaggedContent/TicketView')),
      },
      {
        exact: true,
        path: FLAGGED_CONTENT_PROFILE_VIEW_ROUTE,
        component: lazy(() => import('src/views/FlaggedContent/ProfileView')),
      },
      {
        path: CUSTOMER_SUPPORT_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            TOOLS: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/CustomerSupport')),
      },
      {
        path: FAQ_VIEW_ROUTE,
        guard: RoleGuard,
        guardProps: {
          requiredPermissions: {
            [CONTENT_TYPES.HELP]: [PERMISSIONS.READ],
          },
          disabledBehavior: 'redirect',
        },
        component: lazy(() => import('src/views/Faq')),
      },
      {
        component: () => <Redirect to={ROUTE_404} />,
      },
    ],
  },
];
