import { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';

import { LOGIN_ROUTE, TOAST_VARIANT } from 'src/constants';
import { useAmplify, useAuth, useUrlSearchParams } from 'src/hooks';

import Loading from '../Loading';
import { AuthGuardProps } from './types';

const AuthGuard = ({ children }: AuthGuardProps) => {
  useAmplify(); // initiate amplify

  const [{ error }] = useUrlSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const { isAuthenticated, isInitialized } = useAuth();

  useEffect(() => {
    if (error) {
      const message = error === 'server_error' ? 'Auth Server Error' : error;
      enqueueSnackbar(message, TOAST_VARIANT.ERROR);
    }
  }, [enqueueSnackbar, error]);

  if (!isInitialized) {
    return <Loading />;
  }
  if (isInitialized && !isAuthenticated) {
    return <Redirect to={{ pathname: LOGIN_ROUTE }} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
