import { useState } from 'react';

import { useStyles } from './index.style';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { DashboardLayoutProps } from './types';

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          {children && (
            <div id="scrollable-content" className={classes.content}>
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
