export interface PaginatedDataType {
  [key: string]: Array<Record<string, unknown> | null>;
}

export type PaginatedQueryDataType = PaginatedDataType & {
  after: string | null;
  hasMore: boolean | null;
  total?: number | null;
};

export type QueryDataType =
  | PaginatedQueryDataType
  | Array<Record<string, unknown> | null>
  | Record<string, unknown>;

export type MutationDataType = Record<string, unknown>;

export interface BasicFields<T> {
  [index: string]: {
    message?: string | null;
    statusCode?: number | null;
    success?: boolean | null;
    data?: T | null;
  } | null;
}

export interface DeprecatedPaginatedQueryResponse {
  [key: string]: {
    message?: string | null;
    code?: string | null;
    success?: boolean | null;
    data?: PaginatedQueryDataType;
  };
}

export interface PaginatedQueryResponse {
  [key: string]: PaginatedQueryDataType;
}

export interface SearchQueryResponse {
  [key: string]: {
    hits: {
      total: {
        value: number;
      };
      hits: {
        source: Record<string, unknown>;
      }[];
    };
  };
}

export const isDeprecatedPaginatedQueryResponseTypeGuard = (
  response: unknown,
): response is DeprecatedPaginatedQueryResponse => {
  if (!response || typeof response !== 'object') return false;

  const responseKeys = Object.keys(response as Record<string, unknown>);
  const key = responseKeys[0];
  const value = (response as Record<string, unknown>)[key];

  return Boolean(
    responseKeys.length === 1 &&
      value &&
      typeof value === 'object' &&
      'data' in value,
  );
};

export const isPaginatedQueryResponseTypeGuard = (
  response: unknown,
): response is PaginatedQueryResponse => {
  if (!response || typeof response !== 'object') return false;

  const responseKeys = Object.keys(response as Record<string, unknown>);
  const key = responseKeys[0];
  const value = (response as Record<string, unknown>)[key];

  return Boolean(
    responseKeys.length === 1 &&
      value &&
      typeof value === 'object' &&
      'after' in value &&
      ('hasMore' in value || 'has_more' in value),
  );
};

export const isSearchQueryResponseTypeGuard = (
  response: unknown,
  queryName: string,
): response is SearchQueryResponse => {
  return (
    typeof response === 'object' && response !== null && queryName in response
  );
};
