import { useMemo } from 'react';

import { VIDEO_S3_CLOUDFRONT_MAP } from 'src/constants';
import { resizeVideoUrl } from 'src/utils';

import { UseVideoProps } from './types';

const useVideo = ({ video, size = '720' }: UseVideoProps) => {
  return useMemo(() => {
    if (!video) {
      return null;
    }
    const isNewCloudfrontUrl =
      /assets((.dev.)|(.sandbox.)|(.))chefiq.com/g.test(video);

    if (isNewCloudfrontUrl) {
      return resizeVideoUrl({ video, size });
    }

    let cloudfrontUpdatedUrl = video;

    Object.keys(VIDEO_S3_CLOUDFRONT_MAP).some((s3Url) => {
      if (video.includes(s3Url)) {
        cloudfrontUpdatedUrl = video.replace(
          s3Url,
          VIDEO_S3_CLOUDFRONT_MAP[s3Url],
        );
        return true;
      }
      return false;
    });

    return resizeVideoUrl({ video: cloudfrontUpdatedUrl, size });
  }, [video, size]);
};

export default useVideo;
