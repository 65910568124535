import { combineReducers } from '@reduxjs/toolkit';

import { reducer as authReducer } from './auth/slice';
import { reducer as settingsReducer } from './settings/slice';

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
});

export default rootReducer;
